import React from 'react'
import { isMobile } from 'react-device-detect';
import { Camera } from '../../Components/AnalyzerComponents/Camera';
import CameraMobile from '../../MobileComponents/Camera';
import { useLocation } from 'react-router-dom';

export default function WebcamLayout() {

    let { state } = useLocation();
    if (state) {
        state = JSON.parse(state);
    }
    if (isMobile) {
        return (
            <CameraMobile
                scoreCategory={state?.getScoreCatrgoryState}
                allIssues={state?.getSkinIssueState}>

            </CameraMobile>
        )
    }
    return (
        <Camera
            scoreCategory={state?.getScoreCatrgoryState}
            allIssues={state?.getSkinIssueState}>

        </Camera>
    )
}
