import React, { useEffect, useState, useContext } from "react";
import Comments from "../Comments";
import SimilarBlogs from "../SimilarBlogs/index";
import SignInToast from "../SignInToast";
import { BASE_URL } from "../../../Pages/Config/Config.js";
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import {
  timestrampToDate,
  esstimatedTimeToRead,
} from "../../../Pages/Utils/Utils";
import { useParams } from "react-router-dom";
import { BlogsApi } from "../../../Network/API";
import likeButton from "../../../Images/likebutton.svg";
import unfillLikeButton from "../../../Images/unfillheart.svg";
import mainContext from "../../../Context/indexContext";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import loaderImg from "../../../Images/Preloader.gif";
import "./style.css";

export default function Articles(props) {
  const blogApi = new BlogsApi();
  const { id } = useParams();
  const [blogData, setBlogData] = useState({});
  const [getLoaderState, setLoaderState] = useState("flex");
  const navigate = useNavigate();
  const themeContext = useContext(mainContext);
  const [filter, setFilter] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const getFullUrls = (urls) => {
    return `${BASE_URL}${urls}`;
  };

  // Function to render HTML content with modifications
  const renderHtmlContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    // Get all <img> elements
    const imgElements = doc.getElementsByTagName("img");
    // Get all <a> elements
    const aElements = doc.getElementsByTagName("a");
    for (let a of aElements) {
      // set html target _blank for new open new tab.
      a.setAttribute("target", "_blank");
    }

    for (let img of imgElements) {
      const src = img.getAttribute("src");
      if (src && src.startsWith("/")) {
        // If the image source starts with "/", convert it to a full URL
        const imageUrl = getFullUrls(src);
        img.setAttribute("src", imageUrl);
        img.removeAttribute("style");
        // Set className and style attributes for styling
        img.setAttribute("className", "p-2 mb-3");
        img.setAttribute("style", "width:100% !important; height:90%");
      }
    }
    // Return the modified HTML content as an object
    return { __html: doc.documentElement.innerHTML };
  };

  // Function to fetch API data
  const getApiData = () => {
    blogApi.getBlogs(id).then((response) => {
      if (response.errorStatus === 404) {
        navigate("/PageNotFound/");
      }
      // Hide the loader
      setLoaderState("none");
      // Set the fetched blog data
      setBlogData(response.data);
    });
  };

  // Function to handle liking blogs
  const BlogsLikes = (blog_id) => {
    blogApi
      .addGeneralLikesAndComment("/api/v1/blog-likes/", {
        blog_id: blog_id,
      })
      .then((response) => {
        // If the user is not authorized, show an error modal
        if (response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        // Fetch updated API data after liking
        getApiData();
      });
  };
  const changeFilter = (value) => {
    setFilter(value);
    return value;
  };

  const blogVisitorCount = async () => {
    // Called the Blog Visitor API.
    blogApi.blogVisitorCount(id).then((response) => {
      // Check if authorization or not FEL-130
      if (response.errorStatus === 401) {
        setShowErrorModal(true);
        return;
      }
    });
  };

  useEffect(() => {
    blogVisitorCount();
    setLoaderState("flex");
    getApiData();
  }, [id, props.refresh]);

  return (
    <>
      {showErrorModal && (
        <SignInToast
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
        />
      )}
      <Container className="p-4 mb-5">
        <div
          className="justify-content-center"
          style={{ display: getLoaderState }}
        >
          <img src={loaderImg} alt="" className="loaderImgClass" />
        </div>
        <Row>
          <Col md={10} xs={7}>
            <h3 className="titleName">{blogData?.title}</h3>
          </Col>
          <Col md={2} xs={5} style={{ color: themeContext.getModeState.color }}>
            <FaEye /> {" "}{blogData?.visited_count} {" "}
            <a role="button">
              {blogData?.user_liked && (
                <img
                  src={likeButton}
                  className="p-1"
                  onClick={() => BlogsLikes(blogData?.blog_id)}
                />
              )}
              {!blogData?.user_liked && (
                <img
                  src={unfillLikeButton}
                  className="p-1"
                  onClick={() => BlogsLikes(blogData?.blog_id)}
                />
              )}
              <span className="badge bg-secondary">{blogData?.total_likes}</span>
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <span className="badge custom-badge p-2">
              {blogData?.blog_category?.name}{" "}
            </span>
          </Col>
          <Col md={8} xs={12}>
            <p style={{ color: themeContext.getModeState.color }}>
              {" "}
              {timestrampToDate(blogData?.posted_at)}&nbsp;&nbsp;
              <span className="bullets">&bull;</span>{" "}
              {esstimatedTimeToRead(blogData?.content)} Min to Read
            </p>
          </Col>
        </Row>
        <Row
          dangerouslySetInnerHTML={renderHtmlContent(blogData?.content)}
          className="image_custom"
          style={{
            color: themeContext.getModeState.color,
          }}
        />
      </Container>
      <div className="border-bottom"></div>
      <Container className="p-4">
        <Comments
          comments={blogData?.comments}
          blog_id={blogData?.blog_id}
          getApiData={getApiData}
          theme={themeContext}
          filter={filter}
          changeFilter={changeFilter}
        />
      </Container>
      <Container>
        <Row className={`align-items-center ${!isMobile ? "p-4" : ""}`}>
          <Col>
            <h5 className="similar_header">Similar Blogs</h5>
          </Col>
          <SimilarBlogs
            blogs={blogData?.similar_blogs}
            theme={themeContext}
            getApiData={getApiData}
          />
        </Row>
      </Container>
    </>
  );
}
