import React, { useState } from 'react'
import MainContext from './indexContext';

const SolutionsContext = (props) => {
    const [getShowState, setShowState] = useState(false);
    return (
        <MainContext.Provider value={{getShowState, setShowState}}>
            {props.children}
        </MainContext.Provider>
    )
}

export default SolutionsContext