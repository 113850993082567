import SignInToast from "../SignInToast";
import React, { useContext, useEffect, useState } from "react";
import { BlogsApi } from "../../../Network/API";
import mainContext from "../../../Context/indexContext";
import { useNavigate } from "react-router-dom";
import likeButton from "../../../Images/likebutton.svg";
import "./style.css";
import unfillLikeButton from "../../../Images/unfillheart.svg";
import { FaEye } from "react-icons/fa";
import {
  timestrampToDate,
  esstimatedTimeToRead,
} from "../../../Pages/Utils/Utils";

export default function MostLiked(props) {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const blogsApis = new BlogsApi();
  const [getBlogs, setBlogs] = useState();
  const navigate = useNavigate();
  const themeContext = useContext(mainContext);

  // Navigate to another page
  const navigateAnoterPage = (slug) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      navigate(`/Blogs/Post-content/${slug}`);
    }, 100); // Increased delay to 100 milliseconds
  };

  // Handle liking blogs
  const likedBlogs = (id) => {
    blogsApis
      .addGeneralLikesAndComment("/api/v1/blog-likes/", {
        blog_id: id,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        blogsApis.topVisitedBlogs("").then((_response) => {
          setBlogs(_response.results);
        });
      });
  };

  useEffect(() => {
    blogsApis.topVisitedBlogs().then((_response) => {
      setBlogs(_response.results);
    });
  }, [props.refersh]);
  return (
    <>
      {showErrorModal && (
        <SignInToast
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
        />
      )}
      <div className="mostLiked">
        <div
          style={{
            backgroundColor: themeContext.getModeState.backgroundColor,
            color: themeContext.getModeState.color,
          }}
        >
          <div className="row justify-content-start mb-2">
            <div className="col-6 col-sm-6">
              <h5
                className="custom-header"
                style={{ color: themeContext.getModeState.color }}
              >
                Most Viewed
              </h5>
            </div>
              <div className="col-6 col-sm-5">
                <button
                  className="btn btn-dark viewallbutton mt-2"
                  onClick={() => navigate("List")}
                >
                  View All
                </button>
              </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            backgroundColor: themeContext.getModeState.backgroundColor,
            color: themeContext.getModeState.color,
          }}
        >
          {getBlogs?.length > 0 ? (
            getBlogs?.map((item, index) => (
              <div
                className="col-12 col-sm-12 col-md-4 mb-3"
                role="button"
                key={index}
              >
                <div
                  className="card shadow"
                  style={{ backgroundColor: themeContext.getModeState.tileBG }}
                >
                  <img
                    src={item.banner_images}
                    className="card-img-top card-images"
                    alt="..."
                    onClick={() => navigateAnoterPage(item.slug)}
                  />
                  <span className="badge custom-badge text-capitalize category-badge">
                    {item.blog_category?.name}
                  </span>
                  <a className="like-button">
                    {item.user_liked && (
                      <img
                        src={likeButton}
                        className="p-1"
                        onClick={() => likedBlogs(item?.blog_id)}
                      />
                    )}
                    {!item.user_liked && (
                      <img
                        src={unfillLikeButton}
                        className="p-1"
                        onClick={() => likedBlogs(item?.blog_id)}
                      />
                    )}
                  </a>
                  <div
                    className="card-body"
                    onClick={() => navigateAnoterPage(item.slug)}
                  >
                    <span className="blogHeading card-title text-capitalize blog-titles">
                      {item.title?.slice(0, 50)}{" "}
                    </span>
                    <p
                      className="card-text blog-p"
                      style={{ color: themeContext.getModeState.color }}
                    >
                      {timestrampToDate(item.posted_at)} {"  "} &bull; {"   "}{" "}
                      {esstimatedTimeToRead(item.content)} min to read {" "}
                      <FaEye
                            role="button"
                            className="mx-1"
                          /> 
                         <span>{item.visited_count}</span>

                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="dataNotFound">
              <div
                className="col-12 alert alert-danger d-flex justify-content-center custom-color"
                role="alert"
              >
                <div>
                  <b>Currently, there are no Blogs.</b>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
