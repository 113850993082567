import React, { useContext } from 'react'
import { BsBoxArrowInDownLeft } from 'react-icons/bs';
import mainContext from '../../../Context/indexContext';
import { isMobile } from 'react-device-detect';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import sampleProductImage from '../../../Images/SampleProduct.png';
import './style.css'

export default function Products(props) {
    const getModalContext = useContext(mainContext);

    const truncate = (str, limit) => {
        return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
    }
    //Closes The Modal
    function handleBackClick() {
        getModalContext.setShowState(false)
    }
    return (
        <>
            <Container className='productsBody' style={{ backgroundColor: props.tileBackground, color: props.tileFontColor }}>
                <Row className='d-flex align-items-center mt-2 p-1 text-center'>
                    <Col className='mx-3'>
                        <span className='productsHeadText' style={{ color: props.tileFontColor }}> Based on your results these are the products recommended.</span>
                    </Col>
                    {!isMobile &&
                        <div className={`${isMobile ? 'd-flex justify-content-center' : 'col-md-1'}`}>
                            <div className="d-flex justify-content-center align-items-center remBack " onClick={handleBackClick}>
                                <BsBoxArrowInDownLeft fill='#ffffff' />
                            </div>
                        </div>
                    }
                </Row>
                <div className={'d-flex justify-content-center align-item-center mt-5 ' + (props.isProductLoading ? '' : 'd-none')}>
                    <Spinner animation="border" />
                </div>
                <Row className='product_scroll'>
                    {props?.getProductResults.map((_value, _key) => {
                        return (
                            <Col md={6} xs={12} key={_key} className='py-1' style={props?.getDisplayProductsState}>
                                <div className="card p-2 product_card shadow" style={{ background: props.tileBackground, color: props.tileFontColor, borderColor: props.tileFontColor }}>
                                    <a target='_blank' rel='noreferrer' href={_value.product_url} className='text-decoration-none'>
                                        <div className="d-flex justify-content-center">
                                            <img src={_value.product_image || sampleProductImage} alt="Sample Product" className="productImg" width={60} />
                                        </div>
                                        <div className="summaryProductCard card-body p-2 mt-1" style={{ background: props.bodyBackground, color: props.tileFontColor, borderRadius: '11px' }}>
                                            <h6 className="card-title productTitle" >{truncate(_value.name, 50)}</h6>
                                            <p className="card-text productDescription">{truncate(_value.description, 100)}</p>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        )
                    })}
                    {/* {temp.map((_value, _key) => {
                    return (
                        <Col key={_key} className='py-3' style={getDisplayConsultantState}>
                            <div className="card p-2" style={{ background: props.tileBackground, color: props.tileFontColor }}>
                                <div className="d-flex justify-content-center">
                                    <img src={sampleDoctorImage} alt="Sample Product" className="sampleImageProduct" width={54} />
                                </div>
                                <div className="summaryProductCard card-body p-3 ">
                                    <h6 className="card-title doctorTitle" style={{ background: props.tileBackground, color: props.tileFontColor }}>Doctor Name</h6>
                                    <p className="card-text doctorDescription">Book Appointment</p>
                                </div>
                            </div>
                        </Col>
                    )
                })} */}
                </Row>
            </Container>
        </>
    )
}
