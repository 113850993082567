import React from 'react';
import { Col, Row } from 'react-bootstrap'
import { GiWaterGallon } from 'react-icons/gi'
import './style.css'

export const Remedies = (props) => {
    return (
        <>
            <span className=''>
                <b> Essential {props.featureName} Remedies for Radient Skin</b>
            </span>
            <div className='remListText scrollbar ' id="style-14">
                {props.data === undefined ? <span>No Data Avaibalbe!</span> :
                    props?.data['content']?.length > 0 ? props.data['content']?.map((_value, _index) => {
                        return (
                            <div className="row" key={_index}>
                                <Col className='mt-3 ' md={1} xs={3}>
                                    <div className="remIcon d-flex align-items-center justify-content-center">
                                        <GiWaterGallon fill='#ffffff' />
                                    </div>
                                </Col>
                                <Col className='mt-3 px-4' md={11} xs={9}>
                                    <Row className=''>
                                        <span className='p-0'>
                                            <b>{_value['title']}</b>
                                        </span>
                                    </Row>
                                    <Row className=''>
                                        <span className='remData p-0'>{_value['description']}</span>
                                    </Row>
                                </Col>
                            </div>
                        )
                    }) :
                        <>
                            <span>No Data Avaibalbe!</span>
                        </>
                }

            </div>
        </>
    )
}