import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import { Col, Container, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { isMobile } from 'react-device-detect';
import { BlogsApi } from '../../Network/API';
import mainContext from '../../Context/indexContext';
import Mode from '../../Components/BlogsComponents/Mode';
import { validateEmail, validateName, validatePhone } from '../Utils/Utils';
import CustomToast from '../../Components/AnalyzerComponents/CustomToast';
import felicitaLogoLight from '../../Images/FelicitaLogoLight.svg';
import felicitaLogoDark from '../../Images/felicita.svg';
import PhotoUploadIcon from '../../Images/AddProfileImage.svg'
import bgDark from '../../Images/Blog_page_Login_Screens_Dark.png'
import bgLight from '../../Images/Blog_page_Login_Screens_Light.png'
import 'react-phone-number-input/style.css';
import '../../css/Profile.css';

export default function Profile() {
    const [getUserDataState, setUserDataState] = useState({});
    const [getUploadedImageState, setUploadedImageState] = useState();
    const [getFNameState, setFNameState] = useState('');
    const [getLNameState, setLNameState] = useState('');
    const [getFNameErrorState, setFNameErrorState] = useState('');
    const [getLNameErrorState, setLNameErrorState] = useState('');
    const [getEmailValueState, setEmailValueState] = useState('');
    const [getEmailErrorState, setEmailErrorState] = useState(null);
    const [getMobileErrorState, setMobileErrorState] = useState(null);
    const [getErrorFlagState, setErrorFlagState] = useState([false, '']);

    const userImageRef = useRef();
    const navigate = useNavigate();
    let { state } = useLocation();
    if (state) {
        state = JSON.parse(state);
    }
    const [getMobileValueState, setMobileValueState] = useState(state?.phoneNo);


    const themeContext = useContext(mainContext);

    //Handles Photo Upload
    function handlePhotoUpload(_event) {
        let photo = getUserDataState;
        photo['photo'] = _event[0].data_url;
        setUploadedImageState(_event[0].data_url);
        setUserDataState(photo);
    }

    //Handles User Form Data
    function handleFormData(_event) {

        let temp = getUserDataState;
        if (_event.target.name === 'first_name') {
            setFNameState(_event.target.value);
            validateName(_event.target.value)
        }
        if (_event.target.name === 'last_name') {
            setLNameState(_event.target.value);
            validateName(_event.target.value)
        }
        if (_event.target.name === 'email') {
            setEmailValueState(_event.target.value);
            validateEmail(_event.target.value)
        }
        temp[_event.target.name] = _event.target.value;
        setUserDataState(temp);

    }

    //Post's User Filled Data And Returns User Token
    function approveData() {
        let temp = getUserDataState;
        temp["mobile"] = getMobileValueState;
        setUserDataState(temp);
        let userData = new BlogsApi();
        userData.completeUserProfile(getUserDataState).then((_response) => {
            console.log(_response)
            if (_response.status === 200) {
                sessionStorage.setItem('token', _response.data['token'])
                sessionStorage.setItem('userImage', _response.data['photo'])
                navigate('/Blogs');
            }
            else if (_response.data.status === 400) {
                setErrorFlagState([true, _response.data.message]);
            }
        })
    }

    //Returns User Image
    async function getImage() {
        if (state?.userImage !== undefined) {
            const base64 = await fetch(state?.userImage)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    return new Promise((res) => {
                        reader.onloadend = () => {
                            res(reader.result);
                        }
                    })
                })
            let photo = getUserDataState;
            photo['photo'] = base64;
            setUploadedImageState(base64);
            setUserDataState(photo);
        }
    }

    useEffect(() => {
        validateName(getFNameState).then((_value) => setFNameErrorState(_value));
        validateName(getLNameState).then((_value) => setLNameErrorState(_value));
        validateEmail(getEmailValueState).then((_value) => setEmailErrorState(_value));
        validatePhone(getMobileValueState).then((_value) => setMobileErrorState(_value));
    }, [getFNameState, getLNameState, getEmailValueState, getMobileValueState])


    useEffect(() => {
        let source = getUserDataState;
        source['source'] = "BLOGS_PORTAL";
        setUserDataState(source);
        getImage();
        if (state?.emailId !== undefined || null) {
            let temp = getUserDataState;
            setEmailValueState(state?.emailId)
            temp["email"] = state?.emailId;
        }
        if (state?.fName !== undefined || null) {
            setFNameState(state?.fName);
            let temp = getUserDataState;
            temp["first_name"] = state?.fName;
        }
        if (state?.lName !== undefined || null) {
            setLNameState(state?.lName);
            let temp = getUserDataState;
            temp["last_name"] = state?.lName;
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=" SignupBg bor" style={{ backgroundImage: isMobile ? '' : `url(${themeContext.getModeState.mode ? bgDark : bgLight})` }}>
                <Mode />
                {
                    getErrorFlagState[0] ?
                        //FEL-166 Needed to update the stage from This Component to current component
                        <CustomToast message={getErrorFlagState[1]} getErrorFlagState={getErrorFlagState[0]} setErrorFlagState={setErrorFlagState} />
                        :
                        ""
                }
                <Container className='bor mt-5'>
                    <Row className='bor'>
                        <Col md={isMobile ? 0 : 5} className='bor'>
                            <Row className='d-flex justify-content-center'>
                                <Col md={3}></Col>
                                <Col md={6} className='bor'>
                                    <div className="d-flex justify-content-center bor">
                                        <img src={themeContext.getModeState.mode ? felicitaLogoDark : felicitaLogoLight} alt="Logo" width="100%"/>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <ImageUploading
                                            multiple
                                            onChange={handlePhotoUpload}
                                            dataURLKey="data_url"
                                        >

                                            {({
                                                imageList,
                                                onImageUpload,
                                                onImageRemoveAll,
                                                onImageUpdate,
                                                onImageRemove,
                                                isDragging,
                                                dragProps,
                                            }) => (
                                                <>
                                                    <button className='photoUpload' onClick={onImageUpload}>
                                                        <img src={getUploadedImageState !== undefined ? getUploadedImageState : PhotoUploadIcon} ref={userImageRef} className="" alt="camera" style={{ background: 'transparent', width: '100%', height: ' auto', borderRadius: '30%' }} />
                                                    </button>
                                                </>

                                            )}
                                        </ImageUploading>
                                    </div>
                                    <div className="d-flex justify-content-center profileSpanTxt mt-1" >
                                        <span style={{ color: themeContext.getModeState.color }}>Add Profile Picture</span>
                                    </div>

                                    <div className="mt-2 ">
                                        <label className='profileTexts' style={{ color: themeContext.getModeState.color }}>First Name</label>
                                        <input type="text" name="first_name" value={getFNameState} maxLength={20} className="form-control profileInpts rounded-3" style={{ backgroundColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : 'white', borderColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : '#ced4da', color: themeContext.getModeState.color }} onChange={(e) => { handleFormData(e) }} disabled={state?.fName ? true : false} />
                                        <div className="d-flex justify-content-end validationTexts">
                                            <span className='validationSpan'>{getFNameErrorState}</span>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <label className='profileTexts' style={{ color: themeContext.getModeState.color }}>Last Name</label>
                                        {/* {FEL-159 Added to set limit of input data} */}
                                        <input type="text" name="last_name" value={getLNameState} maxLength={20} className="form-control profileInpts rounded-3" style={{ backgroundColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : 'white', borderColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : '#ced4da', color: themeContext.getModeState.color }} onChange={(e) => { handleFormData(e) }} disabled={state?.lName ? true : false} />
                                        <div className="d-flex justify-content-end validationTexts">
                                            <span className='validationSpan'>{getLNameErrorState}</span>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <label className='profileTexts' style={{ color: themeContext.getModeState.color }}>Email</label>
                                        {/* {FEL-159 Added to set limit of input data} */}
                                        <input type="email" name="email" value={getEmailValueState} maxLength={50} className="form-control profileInpts rounded-3" style={{ backgroundColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : 'white', borderColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : '#ced4da', color: themeContext.getModeState.color }} onChange={(e) => { handleFormData(e) }} disabled={state?.emailId ? true : false} />
                                        <div className="d-flex justify-content-end validationTexts">
                                            <span className='validationSpan'>{getEmailErrorState}</span>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <label className='profileTexts' style={{ color: themeContext.getModeState.color }}>Phone Number</label>
                                        {/* {FEL-159 Added to set limit of input data} */}
                                        <PhoneInput
                                            name="mobile"
                                            placeholder="Enter phone number"
                                            //Quick Fix
                                            limitMaxLength={true}
                                            value={getMobileValueState}
                                            disabled={state?.phoneNo ? true : false}
                                            onChange={setMobileValueState} />
                                        <div className="d-flex justify-content-end validationTexts">
                                            <span className='validationSpan'>{getMobileErrorState}</span>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <label className='text-secondary profileTexts'>Gender</label>
                                        <div className="d-flex justify-content-around ">
                                            <input className="form-check-input" type="radio" name="gender" id="exampleRadios1" value="MALE" onChange={(e) => { handleFormData(e) }} />
                                            <label className="form-check-label profileTexts" style={{ color: themeContext.getModeState.color }}>
                                                Male
                                            </label>

                                            <input className="form-check-input" type="radio" name="gender" id="exampleRadios2" value="FEMALE" onChange={(e) => { handleFormData(e) }} />
                                            <label className="form-check-label profileTexts" style={{ color: themeContext.getModeState.color }}>
                                                Female
                                            </label>

                                            <input className="form-check-input" type="radio" name="gender" id="exampleRadios3" value="OTHERS" onChange={(e) => { handleFormData(e) }} />
                                            <label className="form-check-label profileTexts" style={{ color: themeContext.getModeState.color }}>
                                                Others
                                            </label>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center mt-2 ">
                                        <button type="button" className={`btn form-control completeBtn h-auto`}
                                            style={{ backgroundColor: themeContext.getModeState.mode ? themeContext.getModeState.tileBG : 'black' }}
                                            onClick={approveData}
                                            disabled={(getFNameErrorState === '' && getLNameErrorState === '' && getEmailErrorState === '' && getMobileErrorState === '') ? false : true}
                                        >Complete</button>
                                    </div>

                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
