import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import "./style.css";
export default function Result(props) {
    const canvasRef = useRef();
    const canvasMaskRef = useRef();
    const imageRef = useRef();
    let resultedCanvas, resultedCanvasContext, maskCanvasElement;

    //Draws Results On Canvas
    function loadData() {
        resultedCanvas = canvasRef.current;
        // FEL-273 For Canvas Draw Quality Improvement
        resultedCanvasContext = canvasRef.current.getContext('2d', { alpha: true });

        maskCanvasElement = canvasMaskRef.current;
        props?.maskCanvas(maskCanvasElement)

        // Get the DPR and size of the canvas
        const dpr = window.devicePixelRatio;
        const rect = resultedCanvas.getBoundingClientRect();

        // Set the "actual" size of the canvas
        resultedCanvas.width = rect.width * dpr;
        resultedCanvas.height = rect.height * dpr;

        // Scale the context to ensure correct drawing operations
        resultedCanvasContext.scale(dpr, dpr);
        // Set the "drawn" size of the canvas
        let canvasWidth = rect.width * 1;
        let canvasHeight = rect.height * 1;
        var userImage = new Image();
        userImage.src = props.base64;
        userImage.onload = () => {
            resultedCanvasContext.drawImage(userImage, 0, 0, canvasWidth, canvasHeight);
        };
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [])


    return (
        <>

            <div className="container-fluid resultViewMain p-0">
                <div className="container-fluid d-flex justify-content-center p-0 canvasCover " style={{ position: 'relative', height: isMobile ? '' : '31rem' }}>
                    <canvas id='resultedCanvas' ref={canvasRef} className='resultedCanvas' style={{ height: isMobile ? '50vh' : '100%' }}></canvas>
                    <canvas id='maskCanvas' ref={canvasMaskRef} className='maskCanvas' style={{ position: 'absolute', transform: 'rotateX(180deg)', height: isMobile ? '50vh' : '100%' }}></canvas>
                </div>
                <img src={props.base64} alt="ANALYZED IMAGEE" ref={imageRef} id="resultedImageId" style={{ display: 'none' }} />
            </div>
        </>
    )
}

Result.protoTypes = {
    analyzedData: PropTypes.object,
}

Result.defaultProps = {
    analyzedData: {
        analyzed_data: {
            combined: {
                image: "IMAGE",
                score: 10,
                category: "DEFAULT"
            }
        }
    },
}
