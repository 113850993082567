//Look Straight Calculation
function calculateLookStraight(_landmarks) {

    // console.log("==>",_landmarks[123].z)
    // console.log(_landmarks[352].z)
    // console.log(_landmarks[152].z)
    if (
        (_landmarks[123].z > 0.65 || _landmarks[123].z < 0.027) ||
        (_landmarks[352].z > 0.09 || _landmarks[352].z < 0.01) ||
        (_landmarks[152].z > 0.65 || _landmarks[152].z < -0.02)
    ) {
        // console.log("==> 1st")
        return false;
    }
    else if (
        ((_landmarks[123].z > 0.6 && _landmarks[123].z < 0.066) ||
            (_landmarks[123].z > 0.01 && _landmarks[123].z < 0.03))
        ||
        ((_landmarks[352].z > 0.09 && _landmarks[352].z < 0.10) ||
            (_landmarks[352].z > 0.01 && _landmarks[352].z < 0.02)) ||
        (_landmarks[152].z > 0.07 || _landmarks[152].z < -0.02)
    ) {
        // console.log("==> 2nd")
        return false;
    }
    else {
        return true;
    }
}

//Face Position Calculation
function calculateFacePosition(_landmarks, _results, FACEMESH_LEFT_IRIS) {
    var irisLeftMinX = -1;
    var irisLeftMaxX = -1;
    var width = _results.image.width;
    var height = _results.image.height;
    for (const point of FACEMESH_LEFT_IRIS) {
        var point0 = _landmarks[point[0]];
        if (irisLeftMinX === -1 || point0.x * width < irisLeftMinX) {
            irisLeftMinX = point0.x * width;
        }
        if (irisLeftMaxX === -1 || point0.x * width > irisLeftMaxX) {
            irisLeftMaxX = point0.x * width;
        }
    }
    var dx = irisLeftMaxX - irisLeftMinX;
    var dX = 11.7;
    var normalizedFocaleX = 1.40625;
    var fx = Math.min(width, height) * normalizedFocaleX;
    var dZ = (fx * (dX / dx)) / 10.0;
    dZ = dZ.toFixed(2);

    //FEL-230
    //Face Position Check
    if (dZ > 34.5 && dZ < 53) {
        return true;
        // console.log("GOOD");
    }
    else if (dZ > 53 || dZ < 34.5) {
        return false;
        // console.log("BAD");
    }

}

export { calculateLookStraight, calculateFacePosition }