import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Logo from "../../../Images/felicita.svg";
import { useNavigate } from "react-router-dom";
import AppStore from "../../../Images/AppStore.svg";
import PlayStore from "../../../Images/GooglePlay.svg";
import "./style.css";

export default function Footer() {
  const navigate = useNavigate();
  var currentDate = new Date();

  //navigate to another page. FEL - 122
  const navigateAnotherPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    navigate("/Blogs");
  };

  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col md={9} xs={12} className={`${isMobile ? "d-flex justify-content-center" : "footer"}`}>
              <div className="py-1">
                <img
                  src={Logo}
                  className="logo"
                  onClick={() => navigateAnotherPage()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Col>
            <Col md={3} className="pt-4 footer-right">
              <h5 className="text-center">Free Beauty Apps To Get Gorgeous, Download Now</h5>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={6}>
              <span className="footer-label">Email</span>
              <br />
              <span className="footer-description">services@felicita.ai</span>
            </Col>
            <Col md={4} xs={6}>
              <span
                className={`footer-label ${
                  isMobile ? "d-flex text-center m-0" : ""
                }`}
              >
                Phone Number
              </span>
              {isMobile ? "" : <br></br>}
              <span
                className={`footer-description ${
                  isMobile ? "d-flex text-center m-0" : ""
                }`}
              >
                +91 12345 67891
              </span>
            </Col>
            <Col
              md={4}
              xs={12}
              className={`d-flex ${
                isMobile ? "justify-content-around" : "justify-content-end"
              }`}
            >
              <img src={AppStore} alt="" className="app-image" />
              <img src={PlayStore} alt="" className="app-image" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={8} className={isMobile?'text-center':''}>
              <span
                className={`footer-description ${
                  isMobile ? "text-center m-0" : ""
                }`}
              >
                Copyright © {currentDate.getFullYear()} felicita.ai. All rights
                reserved.
              </span>
            </Col>
            <Col
              md={4}
              className={`d-flex ${
                isMobile ? "justify-content-center " : "justify-content-end"
              }`}
            >
              <span
                className={`footer-description ${
                  isMobile ? "d-flex text-center m-0 mt-2" : ""
                }`}
              >
                Privacy Policy | Terms and Conditions
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
