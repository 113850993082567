import React, { useEffect, useState } from 'react'
import MainContext from './indexContext';


const ThemeModeContext = (props) => {
    // FEL-292 Updated Structure Dark Mode Reset And CallBack
    const [getModeState, setModeState] = useState({});
    var checkMode = localStorage.getItem('mode');
    let modeData = JSON.parse(checkMode);
    function isEmpty(obj) {
        for (const prop in obj) {
          if (Object.hasOwn(obj, prop)) {
            return false;
          }
        }
      
        return true;
      }
    function initState() {
        if (isEmpty(modeData)) {
            setModeState({
                color: '#000000',
                backgroundColor: '#ECEEF5',
                tileBG: '#FFFFFF',
                tileFont: '#000000',
                innerText: '#0000001a',
                mode: false,
            });
            localStorage.setItem('mode', JSON.stringify({
                color: '#000000',
                backgroundColor: '#ECEEF5',
                tileBG: '#FFFFFF',
                tileFont: '#000000',
                innerText: '#0000001a',
                mode: false,
            }));
        }
        else {
            setModeState(modeData);
        }
    }
    const [getButtonTextState, setButtonTextState] = useState('Dark Mode');
    document.body.style.backgroundColor = getModeState.backgroundColor;
    useEffect(() => {
      localStorage.setItem('mode',JSON.stringify(getModeState))
    }, [getModeState])
    
    useEffect(() => {       
        initState();
        // eslint-disable-next-line
    }, [])


    return (
        <MainContext.Provider value={{
            getModeState, setModeState,
            getButtonTextState, setButtonTextState
        }}>
            {props.children}
        </MainContext.Provider>
    )
}

export default ThemeModeContext