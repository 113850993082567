import React, { useContext } from 'react'
import mainContext from '../../../Context/indexContext';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

export default function Mode() {

    const themeContext = useContext(mainContext);
    function toggleMode() {
        if (themeContext.getModeState.backgroundColor === '#ECEEF5') {
            themeContext.setModeState({
                color: '#FFFFFF',
                backgroundColor: '#1E1E1E',
                tileBG: '#1E2029',
                tileFont: '#FFFFFF',
                innerText: '#FFFFFF80',
                mode: true
            })
            themeContext.setButtonTextState('Light Mode')
        }
        else {
            themeContext.setModeState({
                color: '#000000',
                backgroundColor: '#ECEEF5',
                tileBG: '#FFFFFF',
                tileFont: '#000000',
                innerText: '#0000001a',
                mode: false
            })
            themeContext.setButtonTextState('Dark Mode')
        }
    }
    return (
        <>
            <div className="form-check form-switch toggleMode blogsModeButton" style={{ position: 'absolute', right: '60px',top:'20px' }} onClick={toggleMode}>
                {themeContext.getButtonTextState === "Dark Mode" ?
                    <MdDarkMode fill="#808080" size={25} />
                    :
                    <MdLightMode fill='#ffffff' size={25} />
                }
            </div>
        </>
    )
}
