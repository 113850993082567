import React, { useEffect, useState } from 'react'
import { Col } from "react-bootstrap"
import { drawMaskScene, getSkinIssueProgramData, initMaskBuffers, loadMaskImage } from '../../../Shader/SkinIssueShader';
import { hexToRgb } from '../../../Pages/Utils/Utils';
import '../Overview/style.css'
export const Overview = (props) => {
    const [getAllScoresState, setAllScoresState] = useState();
    const [getScoreState, setScoreState] = useState();
    const [getMaskDataState, setMaskDataState] = useState({});

    var date = new Date();
    var day = ("0" + date.getDate()).slice(-2);
    var month = date.getMonth();
    var year = date.getFullYear();
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var getWeekDayDay = date.getDay();
    let displayDate = "" + day + "-" + monthNames[month].match(/.{1,3}/) + "-" + year + ' - ' + weekday[getWeekDayDay];

    let maskIntensity = 0.8;

    let maskCanvasElement;
    maskCanvasElement = props.maskCanvas;

    var webglCtx = maskCanvasElement?.getContext("webgl", {
        preserveDrawingBuffer: true,
        premultipliedAlpha: true,
    });


    //Handles Click For Displaying Results
    async function handleOverviewClick() {
        //FEL-104 To Reset Toggle Actions
        if (props?.setUserViewImageState) {
            props.setUserViewImageState([false, -1])
        }
        webglCtx.clear(webglCtx.DEPTH_BUFFER_BIT);
        webglCtx.clear(webglCtx.DEPTH_BUFFER_BIT | webglCtx.COLOR_BUFFER_BIT);
        webglCtx = maskCanvasElement?.getContext("webgl", {
            preserveDrawingBuffer: true,
            premultipliedAlpha: true,
        });

        for (let i = 0; i < props.allIssues.length; i++) {
            const element = props.allIssues[i];

            var convertedColor = hexToRgb(element['colour']);
            let glImageBase = getSkinIssueProgramData(
                webglCtx
            );
            glImageBase.texture =
                loadMaskImage(
                    webglCtx,
                    getMaskDataState[element['skin_issue']],
                );
            glImageBase.buffers =
                initMaskBuffers(webglCtx);
            drawMaskScene(
                webglCtx,
                glImageBase.programInfo,
                glImageBase.buffers,
                glImageBase.texture,
                element['skin_issue'] === 'Dark_Circles' ? 0.3 : maskIntensity,
                [(convertedColor.r / 255), (convertedColor.g / 255), (convertedColor.b / 255)]
            );
            webglCtx.deleteTexture(glImageBase.texture);
        }
    }

    //Returns Score Details For Every Scores
    function getScoreDetails(_scoreValue) {
        let scoreCategory = null;
        for (let i = 0; i < props?.scoreCategory.length; i++) {
            const element = props?.scoreCategory[i];
            if (_scoreValue >= element['min_val'] && _scoreValue <= element['max_val']) {
                scoreCategory = element;
            }
        }
        return scoreCategory;
    }

    //Returns Score According To Issue Name
    function featureScoreDetails(_featureName) {
        let featureScore = null;
        if (getAllScoresState) {
            featureScore = getAllScoresState[_featureName];
        }
        return featureScore;
    }

    //Returns Total Score
    function setTotalScore() {
        if (getAllScoresState) {
            let setScore = 0;
            for (let i = 0; i < Object.keys(getAllScoresState).length; i++) {
                const element = Object.keys(getAllScoresState)[i];
                setScore += getAllScoresState[element];
                setScoreState(setScore / 6);
            }
        }
    }


    useEffect(() => {
        setAllScoresState(props.score);
        setTotalScore();
        setMaskDataState(props.maskImages);
        // eslint-disable-next-line
    }, [props])

    return (
        <>
            <div className="">
                <div className='OverViewCard mt-3' style={{ backgroundColor: props.tileBackground, color: props.tileFontColor }}>
                    <div className='d-flex align-items-center p-1'>
                        <Col md={3} xs={4} className='d-flex p-1 justify-content-center align-items-center'>
                            <div className={`circular-progress ${props.tileBackground === '#1E2029' ? 'darkProgress' : ""}`} onClick={handleOverviewClick} style={{ background: `conic-gradient(${getScoreDetails(Math.round(getScoreState))?.color} ${Math.round(getScoreState) * 3.6}deg, #ededed 0deg)` }}>
                                <div className={`circular-progress2`} style={{ background: ` conic-gradient(#00000000 0deg,${getScoreDetails(Math.round(getScoreState))?.color} 0deg)` }}>
                                    <div className={`circular-progress3`} style={{ background: `conic-gradient(#00000000 0deg, ${props.tileBackground} 0deg` }}>
                                        <span className="progress-value" >
                                            <b>
                                                {getScoreState ? Math.round(getScoreState) : 0}
                                            </b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={7} xs={8} className='d-flex'>
                            <Col md={12} className='d-flex'>
                                <div className="flex-row">
                                    <div className="mt-2">
                                        <p className='Ov_Heading mb-0'>
                                            Overview
                                        </p>
                                        <p className='mb-1 Ov_Date'>{displayDate}</p>
                                    </div>
                                    <div className="myH3 flex-row">Skin Health Is <span className='OV_skinType' style={{ color: getScoreDetails(Math.round(getScoreState))?.color }}> {getScoreDetails(Math.round(getScoreState))?.category_name}</span></div>
                                    <div className="mt-2 myH4 d-flex align-items-end flex-row" style={{ width: '90%' }}>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='mx-1'>
                            {props?.allIssues.map((_value, _key) => {
                                return (
                                    <span key={_key}>
                                        <span className="OV_Features" style={{ color: getScoreDetails(featureScoreDetails(_value['skin_issue']))?.color }}>{_value['skin_issue'].includes('_') ? _value['skin_issue'].split('_')[0] + " " + _value['skin_issue'].split('_')[1] : _value['skin_issue']} </span>
                                        <span style={{ color: props.tileFontColor }}> | </span>
                                    </span>
                                )
                            })}

                        </p>
                    </div>

                    <div className='d-flex justify-content-center align-items-center'>
                        <div className={`d-flex justify-content-center`}>
                            <div className=" d-flex align-items-center">
                                <div className="excellentIcon"></div>
                                <div className="excellentText mx-1">Excellent</div>
                            </div>
                            <div className=" d-flex align-items-center">
                                <div className="goodIcon"></div>
                                <div className="goodText mx-1">Good</div>
                            </div>
                            <div className=" d-flex align-items-center">
                                <div className="moderateIcon"></div>
                                <div className="moderateText mx-1">Moderate</div>
                            </div>
                            <div className=" d-flex align-items-center">
                                <div className="poorIcon"></div>
                                <div className="poorText mx-1">Poor</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
