import React, { useContext, useState, useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import OTPInput, { ResendOTP } from "otp-input-react";
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { BlogsApi } from '../../Network/API';
import Mode from '../../Components/BlogsComponents/Mode';
import mainContext from '../../Context/indexContext';
import felicitaLogoLight from '../../Images/FelicitaLogoLight.svg';
import felicitaLogoDark from '../../Images/felicita.svg';
import bgDark from '../../Images/Blog_page_Login_Screens_Dark.png'
import bgLight from '../../Images/Blog_page_Login_Screens_Light.png'
import '../../css/OtpAuth.css'
import { onSignUp } from '../Utils/MobileOTPUtil';
import CustomToast from '../../Components/AnalyzerComponents/CustomToast';

export default function OtpAuth() {
    const [getOtpValueState, setOtpValueState] = useState('');
    const [getOtpErrorState, setOtpErrorState] = useState('');
    const [getEndTimerState, setEndTimerState] = useState('');
    const [getErrorFlagState, setErrorFlagState] = useState([false, '']);
    const proceedBtnRef = useRef();
    const navType = useNavigationType();
    const navigate = useNavigate();
    let { state } = useLocation();
    let otpAuth = new BlogsApi();
    if (state) {
        state = JSON.parse(state);
    }

    const themeContext = useContext(mainContext);

    //Handles Back To Homepage
    function handleBackSignClick() {
        navigate("/Blogs");
    }

    //Handles Error Message State
    function handleOtpInputError() {
        if (getOtpValueState.length === 6) {
            setOtpErrorState('');
        }
        else {
            setOtpErrorState('Invalid OTP');
        }
    }

    //Handles Entered OTP Verification For Email And Mobile
    function handleVerification() {
        if (state?.emailId) {
            otpAuth.verifyUserEmailOTP(state?.emailId, getOtpValueState).then((_response) => {
                if (_response.status === 200) {
                    otpAuth.checkUser({ source: 'BLOGS_PORTAL', email: state?.emailId }).then((__response) => {
                        if (__response) {
                            otpAuth.completeUserProfile({ source: 'BLOGS_PORTAL', email: state?.emailId }).then((___response) => {
                                if (___response.status === 200) {
                                    sessionStorage.setItem('token', ___response.data['token'])
                                    sessionStorage.setItem('userImage', ___response.data['photo'])
                                    navigate('/Blogs');
                                }
                            });
                        }
                        else if (!__response) {
                            navigate('/Blogs/CompleteProfile', { replace: true, state: JSON.stringify({ emailId: state?.emailId }) });
                        }
                    });
                }
                else if (_response.status === 203) {
                    setErrorFlagState([true, _response.data.message]);
                }
                else if (_response.status === 408) {
                    setErrorFlagState([true, _response.data.message]);
                }

            });
        }
        else if (state?.phoneNo) {
            verifyOTP();
        }

    }

    //Handles Timer Complete Status
    function handleTimerConplete() {
        setEndTimerState('timerActive');
    }

    //Handles Resend OTP For Email And Mobile
    function handleResendOTP() {
        setEndTimerState('');
        if (state?.emailId) {
            otpAuth.getUserEmailAuthentication(state?.emailId);
        }
        else if (state?.phoneNo) {
            onSignUp(state?.phoneNo, proceedBtnRef)
        }
    }

    //Mobile OTP Vefification
    function verifyOTP() {
        const code = getOtpValueState;
        try {
            window.confirmationResult.confirm(code).then((result) => {
                // // User signed in successfully.
                // const user = result.user;
                otpAuth.checkUser({ source: 'BLOGS_PORTAL', mobile: state?.phoneNo }).then((__response) => {
                    if (__response) {
                        otpAuth.completeUserProfile({ source: 'BLOGS_PORTAL', mobile: state?.phoneNo }).then((___response) => {
                            if (___response.status === 200) {
                                sessionStorage.setItem('token', ___response.data['token'])
                                sessionStorage.setItem('userImage', ___response.data['photo'])
                                navigate('/Blogs');
                            }
                        });
                    }
                    else if (!__response) {
                        navigate('/Blogs/CompleteProfile', { replace: true, state: JSON.stringify({ phoneNo: state?.phoneNo }) });
                    }
                });

            }).catch((error) => {
                setErrorFlagState(true);
            });
        } catch (error) {

        }

    }

    //FEL-171,FEL-174
    //Handle Unwanted Redirects
    useEffect(() => {
        //Quick Fix
        window.addEventListener('popstate', () => {
            navigate('/Blogs/User');
        })
        if (navType === 'POP') {
            navigate('/Blogs/User');
        }
        // eslint-disable-next-line
    },[navType])
    useEffect(() => {
        handleOtpInputError()
        // eslint-disable-next-line
    }, [getOtpValueState])

    return (
        <>
            <div className=" SignupBg bor" style={{ backgroundImage: isMobile ? '' : `url(${themeContext.getModeState.mode ? bgDark : bgLight})` }}>
                <Mode />
                {
                    getErrorFlagState[0] ?
                        <CustomToast message={getErrorFlagState[1]} getErrorFlagState={getErrorFlagState[0]} setErrorFlagState={setErrorFlagState} />
                        :
                        ""
                }
                <Container className='bor mt-5'>
                    <Row className='bor'>
                        <Col md={isMobile ? 0 : 5} className='bor'>
                            <Row className='d-flex justify-content-center'>
                                <Col md={3} xs={0}></Col>
                                <Col md={6} xs={8} className='bor'>
                                    <div className="d-flex justify-content-center bor">
                                        <img src={themeContext.getModeState.mode ? felicitaLogoDark : felicitaLogoLight} alt="Logo"  width='100%'/>
                                    </div>

                                    <div className=''>
                                        <div className="mt-3 mb-3">
                                            <span className='d-flex justify-content-center otpTexts' style={{ color: themeContext.getModeState.color }}>Please enter the verification code we’ve sent you at {state?.emailId ? state?.emailId : state?.phoneNo}</span>
                                        </div>
                                        <label className='mt-3 otpTexts' style={{ color: themeContext.getModeState.color }}>OTP</label>
                                        <div className='otpDiv mt-2'>
                                            <OTPInput
                                                value={getOtpValueState}
                                                onChange={setOtpValueState}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                className="otpInput"
                                            />
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex justify-content-start validationTexts">
                                                    <ResendOTP
                                                        maxTime={120}
                                                        onTimerComplete={handleTimerConplete}
                                                        onResendClick={() => handleResendOTP()}
                                                        className={`resendTimer ${getEndTimerState}`}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end validationTexts">
                                                    <span className="validationSpan">{getOtpErrorState}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <button type="button" ref={proceedBtnRef} className={`btn proceedBtn agreed `} onClick={handleVerification} disabled={getOtpValueState.length === 6 ? false : true}>Proceed</button>
                                    </div>
                                </Col>
                                <Col md={3} xs={0}></Col>
                            </Row>
                        </Col>
                        <Col md={7} className={`${isMobile ? 'd-none' : ''} bor`}>
                            <Row className="">
                                <Col md={4} className='bor'></Col>
                                <Col md={7} className='bor'>
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex">
                                            <button type="button" className='btn btn-primary border-secondary signBackText d-flex' onClick={handleBackSignClick}>
                                                <span className='mx-2'>Back To Blogs</span>
                                                <div className="SignbackArrow">
                                                    <RiArrowGoBackFill fill='#000000' />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
