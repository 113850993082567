import React, { useContext, useEffect, useState } from "react";
import likeButton from "../../../Images/likebutton.svg";
import unfillLikeButton from "../../../Images/unfillheart.svg";
import { useNavigate } from "react-router-dom";
import { BlogsApi } from "../../../Network/API";
import Pagination from "react-bootstrap/Pagination";
import Nav from "react-bootstrap/Nav";
import SignInToast from "../SignInToast";
import { FaEye } from "react-icons/fa";
import "./style.css";
import {
  timestrampToDate,
  esstimatedTimeToRead,
} from "../../../Pages/Utils/Utils";
import { isMobile } from "react-device-detect";
import mainContext from "../../../Context/indexContext";
export default function Blogs(props) {
  const PAGINATE_BY = 10;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const blogsApis = new BlogsApi();
  const [getBlogs, setBlogs] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeCategory, setActiveCategory] = useState("All");
  const themeContext = useContext(mainContext);

  // Click handler for category selection
  const handleClick = (name) => {
    setActiveCategory(name);
    setActiveIndex(1);
    let cateogry = `?category=${name}`;
    // if name is all then cateogry should be empty.
    if (name === "All") {
      cateogry = "?category=";
    }
    blogsApis.blogsApi(cateogry).then((_response) => {
      setBlogs(_response?.results);
      setTotalPagesCount(
        Math.ceil(Math.max(parseInt(_response.count) / PAGINATE_BY, 1))
      );
    });
  };
  // Navigate to another page
  const navigateAnoterPage = (slug) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      navigate(`/Blogs/Post-content/${slug}`);
    }, 100); // Increased delay to 100 milliseconds
  };

  // Handle like blogs
  const likedBlogs = (id) => {
    blogsApis
      .addGeneralLikesAndComment("/api/v1/blog-likes/", {
        blog_id: id,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        // FEL 266 solved problem on this tickets.
        let filterUrl = `?page=${activeIndex}&category=${activeCategory}`;
        if (activeCategory === "All") {
          filterUrl = `?page=${activeIndex}`;
        }
        blogsApis.blogsApi(filterUrl).then((_response) => {
          setBlogs(_response?.results);
          setTotalPagesCount(
            Math.ceil(Math.max(parseInt(_response.count) / PAGINATE_BY, 1))
          );
        });
      });
  };

  // Handle pagination change
  const onClickPaginationChagne = (index) => {
    if (index <= 0 || index > totalPagesCount) return;
    setActiveIndex(index);
    let filterUrl = `?page=${index}&category=${activeCategory}`;
    if (activeCategory === "All") {
      filterUrl = `?page=${index}`;
    }
    blogsApis.blogsApi(filterUrl).then((_response) => {
      setBlogs(_response.results);
    });
  };

  // Fetch initial data on component mount
  useEffect(() => {
    blogsApis.blogsApi("").then((_response) => {
      setBlogs(_response.results);
      setTotalPagesCount(
        Math.ceil(Math.max(parseInt(_response.count) / PAGINATE_BY, 1))
      );
    });
    blogsApis.getCategory().then((_response) => {
      setCategoryData(_response);
    });
  }, [props.refresh]);

  return (
    <>
      {/* Show error modal if needed */}
      {showErrorModal && (
        <SignInToast
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
        />
      )}
      <div className="blog-container">
        <div
          className="navbar-links p-3"
          style={{
            backgroundColor: themeContext.getModeState.backgroundColor,
            color: themeContext.getModeState.color,
          }}
        >
          <div className="row justify-content-start">
            <div className="col-6 col-sm-6 col-md-5">
              <h5
                className="custom-header"
                style={{ color: themeContext.getModeState.color }}
              >
                Category
              </h5>
            </div>
          </div>
          <div className="">
            <div className={`col-md-11 mb-1 ${isMobile ? 'mobCategories' : ''}`}>
              <Nav
                variant="navbar-links"
                activeKey={activeCategory}
                onSelect={(selectedKey) => setActiveCategory(selectedKey)}
                className={isMobile ? "mobNav" : "categoryNav"}
              >
                <Nav.Item className="mx-1 myNav text-center">
                  <Nav.Link
                    eventKey={"All"}
                    onClick={() => handleClick("All")}
                    style={{
                      backgroundColor:
                        themeContext.getModeState.backgroundColor,
                      color: themeContext.getModeState.color,
                    }}
                    className="categoryBtn"
                  >
                    All
                  </Nav.Link>
                </Nav.Item>
                {categoryData?.map((item, index) => (
                  <Nav.Item key={index} className="mx-1 myNav text-center">
                    <Nav.Link
                      eventKey={item.name}
                      onClick={() => handleClick(item.name)}
                      style={{
                        backgroundColor:
                          themeContext.getModeState.backgroundColor,
                        color: themeContext.getModeState.color,
                      }}
                      className="categoryBtn"
                    >
                      {item.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            backgroundColor: themeContext.getModeState.backgroundColor,
            color: themeContext.getModeState.color,
          }}
        >
          {getBlogs?.length > 0 ? (
            getBlogs?.map((item, index) => (
              <div
                className="col-12 col-sm-12 col-md-4 mb-3"
                role="button"
                key={index}
              >
                <div
                  className="card shadow"
                  style={{ backgroundColor: themeContext.getModeState.tileBG }}
                >
                  <img
                    src={item.banner_images}
                    className="card-img-top card-images"
                    alt="..."
                    onClick={() => navigateAnoterPage(item.slug)}
                  />
                  <span className="badge custom-badge text-capitalize category-badge">
                    {item.blog_category?.name}
                  </span>
                  <a className="like-button">
                    {item.user_liked && (
                      <img
                        src={likeButton}
                        className="p-1"
                        onClick={() => likedBlogs(item?.blog_id)}
                      />
                    )}
                    {!item.user_liked && (
                      <img
                        src={unfillLikeButton}
                        className="p-1"
                        onClick={() => likedBlogs(item?.blog_id)}
                      />
                    )}
                  </a>
                  <div
                    className="card-body"
                    onClick={() => navigateAnoterPage(item.slug)}
                  >
                    <span className="blogHeading card-title text-capitalize blog-titles">
                      {item.title}{" "}
                    </span>
                    <p
                      className="card-text blog-p"
                      style={{ color: themeContext.getModeState.color }}
                    >
                      {timestrampToDate(item.posted_at)} {"  "} &bull; {"  "}{" "}
                      {esstimatedTimeToRead(item.content)} min to read{" "}
                      <FaEye role="button" className="mx-1" />
                      <span>{item.visited_count}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-3">
              <div className="col-12 alert alert-danger d-flex justify-content-center custom-color">
                <div>
                  <b>The category currently has no blogs.</b>
                </div>
              </div>
            </div>
          )}
          {getBlogs?.length > 0 && (
            <div className="my-5 d-flex justify-content-center align-content-center">
              <Pagination
                className={
                  themeContext.getModeState.backgroundColor === "#1E1E1E"
                    ? "paginationDark"
                    : "paginationLight"
                }
              >
                <Pagination.First
                  onClick={() => onClickPaginationChagne(1)}
                  className={
                    themeContext.getModeState.backgroundColor === "#1E1E1E"
                      ? "paginationDark"
                      : "paginationLight"
                  }
                />
                <Pagination.Prev
                  onClick={() => onClickPaginationChagne(activeIndex - 1)}
                  className={
                    themeContext.getModeState.backgroundColor === "#1E1E1E"
                      ? "paginationDark"
                      : "paginationLight"
                  }
                />

                {[...Array(totalPagesCount)].map((_, index) => {
                  const pageNumber = index + 1;
                  const isEllipsis =
                    pageNumber !== totalPagesCount - 1 &&
                    Math.abs(activeIndex - pageNumber) > 2;
                  if (isEllipsis) {
                    return (
                      <Pagination.Ellipsis key={`ellipsis-${pageNumber}`} />
                    );
                  }

                  return (
                    <Pagination.Item
                      key={index}
                      onClick={() => onClickPaginationChagne(pageNumber)}
                      active={activeIndex === pageNumber}
                      className={
                        themeContext.getModeState.backgroundColor === "#1E1E1E"
                          ? "paginationDark"
                          : "paginationLight"
                      }
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                })}

                <Pagination.Next
                  onClick={() => onClickPaginationChagne(activeIndex + 1)}
                  className={
                    themeContext.getModeState.backgroundColor === "#1E1E1E"
                      ? "paginationDark"
                      : "paginationLight"
                  }
                />
                <Pagination.Last
                  onClick={() => onClickPaginationChagne(totalPagesCount)}
                  className={
                    themeContext.getModeState.backgroundColor === "#1E1E1E"
                      ? "paginationDark"
                      : "paginationLight"
                  }
                />
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
