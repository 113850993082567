import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RiArrowGoBackFill } from 'react-icons/ri';
import PhoneInput from 'react-phone-number-input';
import { isMobile } from 'react-device-detect';
import { signInWithPopup } from "firebase/auth";
import { BlogsApi } from '../../Network/API';
import mainContext from '../../Context/indexContext';
import { auth, provider } from '../Config/firebaseConfig';
import Mode from '../../Components/BlogsComponents/Mode';
import CustomToast from '../../Components/AnalyzerComponents/CustomToast';
import { validateEmail, validatePhone } from '../Utils/Utils';
import { onSignUp } from '../Utils/MobileOTPUtil';
import felicitaLogoLight from '../../Images/FelicitaLogoLight.svg';
import felicitaLogoDark from "../../Images/felicita.svg";
import googleIcon from '../../Images/Google_Icon.png';
import appleIcon from '../../Images/apple_logo.png';
import bgDark from '../../Images/Blog_page_Login_Screens_Dark.png'
import bgLight from '../../Images/Blog_page_Login_Screens_Light.png'
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import '../../css/UserAuth.css'

export default function UserAuth() {
    const [getFieldState, setFieldState] = useState('');
    const [getFieldActiveState, setFieldActiveState] = useState('activeClass');
    const [getCompState, setCompState] = useState('Email');
    const [getEmailValueState, setEmailValueState] = useState('');
    const [getMobileValueState, setMobileValueState] = useState();
    const [getTermsAgreedState, setTermsAgreedState] = useState(false);
    const [getEmailErrorState, setEmailErrorState] = useState(null);
    const [getMobileErrorState, setMobileErrorState] = useState(null);
    const [getErrorFlagState, setErrorFlagState] = useState([false, '']);

    const proceedBtnRef = useRef();
    const recaptchDivRef = useRef();
    const navigate = useNavigate();
    let userAuthData = new BlogsApi();

    const themeContext = useContext(mainContext);

    //Handles Toogle For Tabs
    function handleNavTabToggle(_Toggler) {
        setCompState(_Toggler);
        if (_Toggler === "Email") {
            if (themeContext.getModeState.mode) {
                setFieldActiveState('activeClassDark')
            }
            else if (themeContext.getModeState.mode === false) {
                setFieldActiveState('activeClass')
            }
            setFieldState('');
        }
        else if (_Toggler === "Phone") {
            if (themeContext.getModeState.mode) {
                setFieldState('activeClassDark')
            } else if (themeContext.getModeState.mode === false) {
                setFieldState('activeClass');
            }
            setFieldActiveState('');
        }

    }

    //Handles Back To HomePage
    function handleBackSignClick() {
        navigate("/Blogs");
    }

    //Handles Privacy Policy Checkbox State
    function handleCheck() {
        if (getTermsAgreedState === false) {
            setTermsAgreedState(true);
        }
        if (getTermsAgreedState === true) {
            setTermsAgreedState(false);
        }
    }

    //Handles OTP Authentication for Email And Mobile
    function handleAuth(_value) {
        if (getCompState === "Email") {
            userAuthData.getUserEmailAuthentication(_value).then((_response) => {
                if (_response === 200) {
                    navigate('/Blogs/EnterOtp', { replace: true, state: JSON.stringify({ emailId: getEmailValueState }) });
                }
            });
        }
        else if (getCompState === "Phone") {
            onSignUp(getMobileValueState, proceedBtnRef).then((__value) => {
                //FEL-167 Error MEssage Handling
                if (__value) {
                    navigate('/Blogs/EnterOtp', { replace: true, state: JSON.stringify({ phoneNo: getMobileValueState }) });
                } else {
                    setErrorFlagState([true,'Enter a valid Phone Number.'])
                }
            });
        }
    }

    //Handles Google Authentication
    function handleGoogleConnect() {
        signInWithPopup(auth, provider).then((_response) => {
            let userRes = _response.user;
            let fName = userRes.displayName.split(/^(\S+)\s/g)[0];
            let lName = userRes.displayName.split(/^(\S+)\s/g)[1];
            userAuthData.checkUser({ source: 'BLOGS_PORTAL', email: _response.user.email }).then((__response) => {
                if (__response) {
                    userAuthData.completeUserProfile({ source: 'BLOGS_PORTAL', email: _response.user.email }).then((___response) => {
                        if (___response.status === 200) {
                            sessionStorage.setItem('token', ___response.data['token'])
                            sessionStorage.setItem('userImage', ___response.data['photo'])
                            navigate('/Blogs');
                        }
                    });
                }
                else if (!__response) {
                    navigate('/Blogs/CompleteProfile', { replace: true, state: JSON.stringify({ emailId: _response.user.email, fName: fName, lName: lName, userImage: userRes.photoURL }) });
                }
            });
        });
    }

    useEffect(() => {
        if (themeContext.getModeState.mode) {
            if (getCompState === 'Email') {
                setFieldActiveState('activeClassDark');
                setFieldState('');
            }
            if (getCompState === 'Phone') {
                setFieldState('activeClassDark');
                setFieldActiveState('');
            }
        }
        if (themeContext.getModeState.mode === false) {
            if (getCompState === 'Email') {
                setFieldActiveState('activeClass');
                setFieldState('');
            }
            if (getCompState === 'Phone') {
                setFieldState('activeClass');
                setFieldActiveState('');
            }
        }
        // eslint-disable-next-line
    }, [themeContext.getModeState.mode])

    useEffect(() => {
        validateEmail(getEmailValueState).then((_value) => setEmailErrorState(_value));
        validatePhone(getMobileValueState).then((_value) => setMobileErrorState(_value));
    }, [getEmailValueState, getMobileValueState])

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token !== null || undefined) navigate('/Blogs');
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className=" SignupBg bor" style={{ backgroundImage: isMobile ? '' : `url(${themeContext.getModeState.mode ? bgDark : bgLight})` }}>
                <Mode />
                {
                    getErrorFlagState[0] ?
                        <CustomToast message={getErrorFlagState[1]} getErrorFlagState={getErrorFlagState[0]} setErrorFlagState={setErrorFlagState} />
                        :
                        ""
                }
                <Container className='bor mt-5'>
                    <Row className='bor'>
                        <Col md={isMobile ? 0 : 5} className='bor'>
                            <Row className='d-flex justify-content-center'>
                                <Col md={3} xs={0}></Col>
                                <Col md={6} xs={8} className='bor'>
                                    <div className="d-flex justify-content-center bor">
                                        <img src={themeContext.getModeState.mode ? felicitaLogoDark : felicitaLogoLight} alt="Logo" width='100%' />
                                    </div>
                                    <div className={`d-flex justify-content-center align-items-center p-0  mt-2 mb-2`}>
                                        <nav className="nav nav-pills navSignInUp p-1 bor" style={{ backgroundColor: themeContext.getModeState.tileBG }}>
                                            <span className={`d-flex justify-content-center nav-link ${getFieldActiveState}`} style={{ color: themeContext.getModeState.color }} onClick={() => handleNavTabToggle("Email")}>Email</span>
                                            <span className={`d-flex justify-content-center nav-link ${getFieldState}`} style={{ color: themeContext.getModeState.color }} onClick={() => handleNavTabToggle("Phone")}>Phone </span>
                                        </nav>
                                    </div>
                                    <div className=''>
                                        {getCompState === 'Email' ?
                                            <label className='bor userTexts' style={{ color: themeContext.getModeState.color }}>Email ID</label> : getCompState === 'Phone' ?
                                                <label className='bor userTexts' style={{ color: themeContext.getModeState.color }}>Phone Number</label> : ''
                                        }
                                        <div className='row'>
                                            {getCompState === 'Email' ?
                                                <div>
                                                    {/* {FEL-159 Added to set limit of input data} */}
                                                    <input type="email" className="form-control rounded-4 dataInputs" placeholder="Enter Email id" value={getEmailValueState} maxLength={50} onChange={(e) => setEmailValueState(e.target.value)} />
                                                    <div className="d-flex justify-content-end validationTexts">
                                                        <span className='validationSpan'>{getEmailErrorState}</span>
                                                    </div>
                                                </div>
                                                : getCompState === 'Phone' ?
                                                    <div>
                                                        {/* {FEL-159 Added to set limit of input data} */}
                                                        <PhoneInput
                                                            className='dataInputs'
                                                            name="mobile"
                                                            placeholder="Enter Phone Number"
                                                            limitMaxLength={true}
                                                            value={getMobileValueState}
                                                            onChange={setMobileValueState} />
                                                        <div className="d-flex justify-content-end validationTexts">
                                                            <span className='validationSpan'>{getMobileErrorState}</span>
                                                        </div>
                                                    </div>

                                                    : ''
                                            }
                                        </div>
                                        <div className="mt-2">
                                            <input className="m-0 userTexts" type="checkbox" value="" style={{ width: '20px' }} checked={getTermsAgreedState} onChange={() => handleCheck()} /><span className='userTexts' style={{ color: themeContext.getModeState.color }}> I agree to Felicita's </span><span className='terms userTexts'>Terms of Use</span> <span className='userTexts' style={{ color: themeContext.getModeState.color }}>and</span> <span className='priv userTexts'>Privacy Policy</span>
                                        </div>
                                        <div className="row mt-3">
                                            <button type="button" id='recaptcha-container' ref={proceedBtnRef} className={`btn rounded-4  proceedBtn ${getTermsAgreedState === true && (getEmailErrorState === '' || getMobileErrorState === '') ? themeContext.getModeState.mode ? 'agreedDark' : 'agreed' : themeContext.getModeState.mode ? 'disAgreedDark' : `disAgreed`}`} disabled={getTermsAgreedState === true && ((getCompState === 'Email' && getEmailErrorState === '') || (getCompState === 'Phone' && getMobileErrorState === '')) ? false : true} onClick={() => handleAuth(getEmailValueState)}>Proceed</button>
                                        </div>
                                        <div className="">
                                            <hr className="hr-text" data-content="OR" />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center rounded-4 mt-2 socialBtn userTexts" style={{ backgroundColor: themeContext.getModeState.tileBG, color: themeContext.getModeState.color }}>
                                            <button type="button" id='recaptcha-container' ref={proceedBtnRef} className={`btn p-0 border-0 `} disabled={!getTermsAgreedState} style={{ cursor: !getTermsAgreedState ? 'not-allowed' : 'pointer', color: themeContext.getModeState.color }} onClick={() => handleGoogleConnect()} >
                                                <img src={googleIcon} alt="GoogleIcon" width={45} className='mx-2 ' /> <span >Connect with Google</span>
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center rounded-4 mt-2 socialBtn userTexts " style={{ backgroundColor: themeContext.getModeState.tileBG, color: themeContext.getModeState.color }} >
                                            <button type="button" id='recaptcha-container' ref={proceedBtnRef} className={`btn p-0 border-0 `} disabled={!getTermsAgreedState} style={{ cursor: !getTermsAgreedState ? 'not-allowed' : 'pointer', color: themeContext.getModeState.color }}>
                                                <img src={appleIcon} alt="GoogleIcon" width={40} className='mx-2 ' /><span>Connect with Apple</span>
                                            </button>
                                        </div>

                                        <div ref={recaptchDivRef} className="recaptchaDiv">

                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} xs={0}></Col>
                            </Row>
                        </Col>
                        <Col md={7} className={`${isMobile ? 'd-none' : ''} bor`}>
                            <Row className="">
                                <Col md={4} className='bor'></Col>
                                <Col md={7} className='bor'>
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex">
                                            <button type="button" className='btn btn-primary border-secondary signBackText d-flex rounded-5' onClick={handleBackSignClick}>
                                                <span className='mx-2'>Back To Blogs</span>
                                                <div className="SignbackArrow">
                                                    <RiArrowGoBackFill fill='#000000' />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
