import React, { useContext, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Solutions } from '../Solutions/'
import mainContext from '../../../Context/indexContext.js';
import { getSkinIssueProgramData, drawMaskScene, loadMaskImage, initMaskBuffers, } from '../../../Shader/SkinIssueShader';
import { hexToRgb, textFormating } from '../../../Pages/Utils/Utils';
import { AnalyzerApi } from '../../../Network/API';
import pre_Loader from '../../../Images/Preloader.gif';
import CustomToast from '../CustomToast';
import "../SkinAnalysis/style.css";
import '../Overview/style.css'
import Products from '../Products';
export const SkinAnalysis = (props) => {
    const [getFeatureNameState, setFeatureNameState] = useState(['', [0, ' 0', '#888888']]);
    const [getMaskDataState, setMaskDataState] = useState({});
    const [getAllResults, setAllResults] = useState({});
    const [getTipsDataState, setTipsDataState] = useState({});
    const [getErrorFlagState, setErrorFlagState] = useState(false);
    const [getUserViewImageState, setUserViewImageState] = useState([false, -1]);
    const [getFilteredDataState, setFilteredDataState] = useState([]);
    // eslint-disable-next-line
    const [getDisplayProductsState, setDisplayProductsState] = useState({});
    const [getProductResults, setProductResults] = useState([]);
    const [isProductLoading, setIsProductLoading] = useState(false);

    const getModalContext = useContext(mainContext);
    let tipRemData = new AnalyzerApi();
    //Returns Score Details According to Scores
    function getScoreDetails(_scoreValue) {
        let scoreCategory = null;
        for (let i = 0; i < props?.scoreCategory.length; i++) {
            const element = props?.scoreCategory[i];
            if (_scoreValue >= element['min_val'] && _scoreValue <= element['max_val']) {
                scoreCategory = element;
            }
        }
        return scoreCategory;
    }



    let maskIntensity = 0.8;

    let maskCanvasElement;
    maskCanvasElement = props.maskCanvas;

    var webglCtx = maskCanvasElement?.getContext("webgl", {
        preserveDrawingBuffer: true,
        premultipliedAlpha: true,
    });
    //Handles Display Of Tips And Remedies
    function handleFeatureDetailClick(_featureKeyName, _featureaName, _a, _b, _c) {
        setIsProductLoading(true);
        setProductResults([]);
        tipRemData.getTipsRemediesData('tips', _featureaName, getScoreDetails(_a)['category_name'], 'General').then((_response) => {
            if (_response !== 500) {
                setTipsDataState(_response);
                getModalContext.setShowState(true);
            }
            else {
                setErrorFlagState(true);
            }
        });

        setFeatureNameState([_featureaName, [_a, _b, _c]]);
        tipRemData.getRecommendedProducts(_featureKeyName, getScoreDetails(_a)['category_name']).then((_response) => {
            setProductResults(_response);
            setIsProductLoading(false);
        });
    }

    //Handles Of Displaying Particular Issue's Image
    function handleViewFeatureClick(_image, _color = '#ff0000', _issue) {
        var convertedColor = hexToRgb(_color);

        webglCtx.clear(webglCtx.DEPTH_BUFFER_BIT);
        webglCtx.clear(webglCtx.DEPTH_BUFFER_BIT | webglCtx.COLOR_BUFFER_BIT);
        webglCtx = maskCanvasElement?.getContext("webgl", {
            preserveDrawingBuffer: true,
            premultipliedAlpha: true,
        });
        let glBase = getSkinIssueProgramData(
            webglCtx
        );
        glBase.texture =
            loadMaskImage(
                webglCtx,
                _image,
            );
        glBase.buffers =
            initMaskBuffers(webglCtx);
        drawMaskScene(
            webglCtx,
            glBase.programInfo,
            glBase.buffers,
            glBase.texture,
            _issue === 'Dark_Circles' ? 0.4 : maskIntensity,
            [(convertedColor.r / 255), (convertedColor.g / 255), (convertedColor.b / 255)]
        );
        webglCtx.deleteTexture(glBase.texture);
        return;

    }

    //Returns Colored Images From Masks
    function handleFeatureImageRender(_img, _color, _issue) {
        var convertedColor = hexToRgb(_color);

        let glImageBase = getSkinIssueProgramData(
            webglCtx
        );
        glImageBase.texture =
            loadMaskImage(
                webglCtx,
                _img,
            );
        glImageBase.buffers =
            initMaskBuffers(webglCtx);
        drawMaskScene(
            webglCtx,
            glImageBase.programInfo,
            glImageBase.buffers,
            glImageBase.texture,
            _issue === 'Dark_Circles' ? 0.2 : maskIntensity,
            [(convertedColor.r / 255), (convertedColor.g / 255), (convertedColor.b / 255)]
        );
        webglCtx.deleteTexture(glImageBase.texture);
    }

    //Clear Canvas
    function clearCanvas() {
        webglCtx.clear(webglCtx.DEPTH_BUFFER_BIT);
        webglCtx.clear(webglCtx.DEPTH_BUFFER_BIT | webglCtx.COLOR_BUFFER_BIT);
        webglCtx = maskCanvasElement?.getContext("webgl", {
            preserveDrawingBuffer: true,
            premultipliedAlpha: true,
        });
    }

    //FEL-104
    //Updated Function To Handle Appropriate Click Action Needed For Toggle

    //Toggle UserChoice To View Image
    function toggleImageView(e, _image, _color) {
        if (e !== getUserViewImageState[1]) {
            clearCanvas();
            if (getUserViewImageState[0]) {
                setUserViewImageState([true, e])
                console.log("NOTSAME", e, getUserViewImageState[1]);
            }
            else {
                handleViewFeatureClick(_image, _color, e);
                setUserViewImageState([!getUserViewImageState[0], e]);
            }
        }
        else if (e === getUserViewImageState[1]) {
            if (getUserViewImageState[0]) {
                clearCanvas();
            }
            else {
                handleViewFeatureClick(_image, _color, e);
            }
            setUserViewImageState([!getUserViewImageState[0], e])
        }
    }

    //Manages Filtered Data
    function errorCheckata() {
        var filteredData = [];
        for (let i = 0; i < props.allIssues.length; i++) {
            const element = props.allIssues[i];
            for (let i = 0; i < Object.keys(getAllResults).length; i++) {
                const elementTwo = Object.keys(getAllResults)[i];
                if (element['skin_issue'] === elementTwo) {
                    filteredData.push(element);
                    setFilteredDataState(filteredData);
                }

            }

        }


    }

    //Added For FEL-104
    useEffect(() => {
        if (props.setUserViewImageState) {
            setUserViewImageState([false, -1])
        }
        // eslint-disable-next-line
    }, [props.setUserViewImageState])

    useEffect(() => {
        if (Object.keys(getAllResults).length > 0) {
            clearCanvas();
            for (let i = 0; i < (props.allIssues).length; i++) {
                const element = props.allIssues[i];
                handleFeatureImageRender(getMaskDataState[element['skin_issue']], element.colour, element['skin_issue'])
            }
        }
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        errorCheckata();
        setAllResults(props.analyzedResults);
        setMaskDataState(props.maskImages);
        // eslint-disable-next-line
    }, [props]);


    return (
        <>
            <div className="analysisMain p-0" style={{ color: props.tileFontColor }}>
                {/* FEL-273 For FIx For Single Time Display Of Error Message & Updated Error Message */}
                {
                    getErrorFlagState ?
                        <CustomToast message="Tips & Remedies are currently unavailable! " getErrorFlagState={getErrorFlagState} setErrorFlagState={setErrorFlagState} />
                        :
                        ""
                }
                <div className={`container-fluid ${isMobile ? 'p-0' : ''}`}>
                    <div className={isMobile ? 'checkTemp' : 'row d-flex justify-content-center'}>
                        <div className={isMobile ? '' : 'd-flex justify-content-center row'}>
                            {
                                Object.keys(getAllResults).length > 0 ? getFilteredDataState?.map((_value, _key) => {
                                    return (
                                        <Row className={`py-1 ${props.tileBackground === "#1E2029" ? 'featDivDark' : 'featDiv'}  mt-1 order-${_value.sequence_id - 1}`} key={_key}>
                                            <Col md={2} xs={4} className={` d-flex justify-content-center align-items-center `}>
                                                <div className={`SA1_CP1 ${props.tileBackground === '#1E2029' ? 'darkProgressSA1_CP1' : ""} my-1`} style={{ background: `conic-gradient(${_value.colour} ${getAllResults[_value['skin_issue']]?.score * 3.6}deg, #ededed 0deg)` }}>
                                                    <div className={`SA1_CP2`} style={{ background: ` conic-gradient(#00000000 0deg,${_value.colour} 0deg)` }}>
                                                        <div className="SA1_CP3" style={{ background: `conic-gradient(#00000000 0deg, ${props.tileBackground} 0deg` }}>
                                                            <span className="SA1_PV1" >
                                                                <b>
                                                                    {getAllResults[_value['skin_issue']]?.score}
                                                                </b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={9} xs={6} className='p-0 '>
                                                <span className='SA_texts'>
                                                    {getAllResults[_value['skin_issue']] ? textFormating(getAllResults[_value['skin_issue']].issue) : ''}
                                                </span>
                                                <p className={`${isMobile ? 'mobissueDescription' : 'issueDescription'}`}>
                                                    {_value.description ? _value.description : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum saepe similique assumenda. Praesentium quibusdam labore libero eligendi possimus, aut molestias consequuntur dicta nisi, ipsa, commodi architecto obcaecati ullam! Repellat, veniam.'}
                                                </p>
                                            </Col>
                                            <Col md={1} xs={2} className='py-1 mobileIconPosition'>
                                                <div className="popupModalIconBackdrop my-1 d-flex justify-content-center align-items-center" style={{ cursor: 'pointer', width: isMobile ? '30px' : '' }} onClick={() => handleFeatureDetailClick(getAllResults[_value['skin_issue']].issue, textFormating(getAllResults[_value['skin_issue']].issue), getAllResults[_value['skin_issue']]?.score, `${getAllResults[_value['skin_issue']]?.score} %`, _value.colour)}>
                                                    <BsBoxArrowUpRight fill="#ffffff" size={15} />
                                                </div>
                                                <div className="visibleInvisibleBackdrop my-1 d-flex justify-content-center align-items-center" style={{ width: isMobile ? '30px' : '' }} onClick={() => toggleImageView(_key, getMaskDataState[_value['skin_issue']], _value.colour)}>
                                                    {getUserViewImageState[0] && getUserViewImageState[1] === _key ?
                                                        <>
                                                            {handleViewFeatureClick(getMaskDataState[_value['skin_issue']], _value.colour, _value['skin_issue'])}
                                                            <AiFillEye size={17} fill="#ffffff" />
                                                        </>
                                                        :
                                                        <>
                                                            <AiFillEyeInvisible size={17} fill="#ffffff" />
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                }) :
                                    // FEL-273 ADD Loader
                                    <>
                                        <div className="d-flex justify-content-center">
                                            <img src={pre_Loader} alt="Loader" style={{ display: `flex`, position: 'absolute', height: '100%', zIndex: "1" }} className="justify-content-center" />
                                        </div>
                                    </>
                            }

                            <Modal show={getModalContext.getShowState} dialogClassName="modal-fullscreen p-4" className='myCustomModal' >
                                <Modal.Body style={{ backgroundColor: 'transparent', borderRadius: '8px' }}>
                                    <div className="row">

                                        <div className={`col-md-7 customModalBackground d-flex justify-content-center ${isMobile ? 'p-0' : ''}`} style={{ backgroundColor: 'transparent' }}>
                                            <Solutions
                                                tipsData={getTipsDataState} scoreCategory={props.scoreCategory}
                                                featureNameDesc={getFilteredDataState}
                                                tileBackground={props.tileBackground} color={props.color} tileFontColor={props.tileFontColor} data={getFeatureNameState} />
                                        </div>
                                        <div className={`col-md-5 customModalBackground justify-content-center ${isMobile ? 'mt-3' : ''}`} style={{ backgroundColor: props.tileBackground }}>
                                            <Products
                                                isProductLoading={isProductLoading}
                                                getProductResults={getProductResults}
                                                getDisplayProductsState={getDisplayProductsState}
                                                bodyBackground={props.bodyBackground}
                                                tileFontColor={props.tileFontColor}
                                                tileBackground={props.tileBackground}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div >
                </div >
            </div>
        </>
    )
}