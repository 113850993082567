import React, { useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FaceMesh, FACEMESH_LEFT_IRIS } from "@mediapipe/face_mesh";
import { Row } from "react-bootstrap";
import CustomToast from "../../Components/AnalyzerComponents/CustomToast";
import Header from "../../Components/AnalyzerComponents/Header";
import { calculateFacePosition, calculateLookStraight } from "../Utils/FaceParametersUtil";
import { AnalyzerApi } from "../../Network/API";
import pre_Loader from "../../Images/Preloader.gif";
import LiveTryOnIcon from "../../Images/LiveTryOn.svg";
import PhotoUploadIcon from "../../Images/PhotoUpload.svg";
import "../../css/LaunchPage.css";

export default function LaunchPage() {
  const [getModeState, setModeState] = useState({});
  const [getDisplayLoaderState, setDisplayLoaderState] = useState(["flex", "blur(8px)"]);
  const [getSkinIssueState, setSkinIssueState] = useState();
  const [getScoreCatrgoryState, setScoreCatrgoryState] = useState();
  const [getErrorFlagState, setErrorFlagState] = useState(false);
  const [getErrorShowFlag, setErrorShowFlag] = useState(false);
  const [getErrorMessagesState, setErrorMessagesState] = useState([]);
  const [getFaceCheckState, setFaceCheckState] = useState([false, false]);
  const [getImageState, setImageState] = useState("");
  const [getConsentState, setConsentState] = useState(false);
  const [getFirstViewedGuidelinesState, setFirstViewedGuidelinesState] = useState(false);
  const [getFirstViewedGuidelinesCheckedState, setFirstViewedGuidelinesCheckedState] = useState(false);
  const onImageUploadVar= useRef();
  let getThemeData = localStorage.getItem("mode");
  let loaderHeight = "100%";
  const navigate = useNavigate();


  //Loads Data From API
  function loadData() {
    var getData = new AnalyzerApi();
    getData.checkEventMode().then((_response) => {
      if (_response.mode === "event") {
        sessionStorage.setItem("eventMode", _response.mode);
      }
      else {
        sessionStorage.setItem("eventMode", "");
      }
    });

    getData.getAllIssues().then((_response) => {
      if (_response !== 500) {
        setSkinIssueState(_response);
      } else {
        setErrorFlagState(true);
        setErrorShowFlag(true);
        setErrorMessagesState(["Internal Server Error"]);
      }
    });

    getData.getScoreData().then((_response) => {
      if (_response !== 500) {
        setScoreCatrgoryState(_response);
      } else {
        setErrorFlagState(true);
        setErrorShowFlag(true);
        setErrorMessagesState(["Internal Server Error"]);
      }
    });
  }
  //Send Data To API
  async function sendImages(_base64, _issue = "Age") {
    setDisplayLoaderState(["flex", "blur(8px)"]);

    setTimeout(() => {
      setDisplayLoaderState(["none", "blur(0px)"]);
      navigate("Layout", { replace: true, state: JSON.stringify({ getScoreCatrgoryState, getSkinIssueState, _base64 }) });
    }, 3000);
  }

  //Handles Facemesh Results
  function onResults(results) {
    if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
      for (const landmarks of results.multiFaceLandmarks) {
        var lookedStraight = calculateLookStraight(landmarks);
        var correctPosition = calculateFacePosition(landmarks, results, FACEMESH_LEFT_IRIS);
        if ((lookedStraight && correctPosition) === false) {
          setDisplayLoaderState(["none", "blur(0px)"]);
          setErrorShowFlag(true);
          // FEL-230
          setErrorMessagesState(["Upload a valid photo."]);
        }
        setFaceCheckState([lookedStraight, correctPosition]);
      }
    }
  }
  //Handles User Uploaded Photo
  async function handlePhotoUpload(_event) {
    setDisplayLoaderState(["flex", "blur(8px)"]);
    const WIDTH = 500;
    const HEIGHT = 500;
    let tempImgData = _event[0].data_url;
    let imageTag = document.createElement("img");
    imageTag.src = tempImgData;
    imageTag.width = WIDTH;
    imageTag.height = HEIGHT;
    // document.body.appendChild(imageTag);
    let canvasTag = document.createElement("canvas");
    canvasTag.width = WIDTH;
    canvasTag.height = HEIGHT;
    let canvasContext = await canvasTag.getContext("2d");
    canvasContext.save();
    canvasContext.clearRect(0, 0, canvasTag.width, canvasTag.height);
    await canvasContext.drawImage(imageTag, 0, 0, canvasTag.width, canvasTag.height);
    canvasTag.style.display = "none";
    document.body.appendChild(canvasTag);
    setImageState(tempImgData);

    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      selfieMode: true,
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });
    setTimeout(() => {
      faceMesh.send({ image: canvasTag }).then(() => { })
        .catch((e) => {
          console.log("Failed", e);
        });
    }, 100);
    faceMesh.onResults(onResults);
  }

  //Handles Live Mode
  function handleLive() {
    navigate("Webcam", {
      replace: true,
      state: JSON.stringify({ getScoreCatrgoryState, getSkinIssueState }),
    });
  }

  //Init Theme
  function initTheme() {
    setModeState({
      fontColor: "#000000",
      backgroundColor: "#ECEEF5",
      tileBG: "#FFFFFF",
      tileFont: "#000000",
      mode: false,
    });
    setTimeout(() => {
      localStorage.setItem("mode", JSON.stringify(getModeState));
    }, 200);
    document.body.style.backgroundColor = getModeState.backgroundColor;
  }
  if (isMobile) {
    loaderHeight = "";
  }

  //Handle User Concent
  function handleConcentCheck() {
    setConsentState(!getConsentState);
  }

  //Handle Click Event Of Image Upload
  function handleImageUploadClick() {
    setFirstViewedGuidelinesState(true);
    if (getFirstViewedGuidelinesCheckedState) {
      onImageUploadVar.current()
    }
  }

  //Assigns a functionto make a function
  function imageUploadAssign(_uplFunction) {
    onImageUploadVar.current = _uplFunction;
  }

  //Handles Storing User Image Consent
  useEffect(() => {
    localStorage.setItem('consent', getConsentState);
  }, [getConsentState])

  //Handles Image Upload If Valid Image
  useEffect(() => {
    if (getFaceCheckState[0] && getFaceCheckState[1]) {
      sendImages(getImageState.split(",")[1]);
    }
    // eslint-disable-next-line
  }, [getFaceCheckState]);

  useEffect(() => {
    if (!getThemeData) {
      initTheme();
    }
    else if (getThemeData) {
      setModeState(JSON.parse(getThemeData));
      document.body.style.backgroundColor = JSON.parse(getThemeData).backgroundColor;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getSkinIssueState !== undefined && getScoreCatrgoryState !== undefined) {
      setDisplayLoaderState(["none", "blur(0px)"]);
    }
  }, [getSkinIssueState, getScoreCatrgoryState]);

  useEffect(() => {
    sessionStorage.clear();
    loadData();
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (getFirstViewedGuidelinesState && getFirstViewedGuidelinesCheckedState) {
      onImageUploadVar.current();
    }
    // eslint-disable-next-line
  }, [getFirstViewedGuidelinesCheckedState])

  return (
    <>
      <Header descText="You are few steps away from your skin health score" welcomeText="Welcome To" setTheme={setModeState} getFirstViewedGuidelinesState={getFirstViewedGuidelinesState} setFirstViewedGuidelinesCheckedState={setFirstViewedGuidelinesCheckedState} />
      {/* FEL-230 */}
      {getErrorShowFlag ? (
        <CustomToast message={getErrorMessagesState[0]} getErrorFlagState={getErrorShowFlag} setErrorFlagState={setErrorShowFlag} />
      ) : ("")
      }
      <div className="d-flex justify-content-center align-items-center mt-2">
        <img src={pre_Loader} alt="Loader" style={{ display: `${getDisplayLoaderState[0]}`, position: "absolute", height: loaderHeight, width: "50%", zIndex: "1" }} className="justify-content-center" />
        <Row className="centerDiv" style={{ filter: `${getDisplayLoaderState[1]}`, backgroundColor: getModeState.tileBG }} >
          <div className="d-flex justify-content-center align-items-end" onClick={handleLive} >
            <button className="LiveTryON" disabled={getErrorFlagState} style={{ cursor: getErrorFlagState ? "not-allowed" : "pointer", backgroundColor: getModeState.backgroundColor, color: getModeState.fontColor }}>
              <img src={LiveTryOnIcon} className="btn_icon_btn" alt="camera" style={{ background: "transparent" }} />
              <p className="m-0"> Live </p>
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <ImageUploading
              onChange={handlePhotoUpload}
              dataURLKey="data_url"
              acceptType={["jpeg", "jpg", "png"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <>
                  {
                    imageUploadAssign(onImageUpload)
                  }
                  <button className="photoUploadLaunch" onClick={() => handleImageUploadClick()} disabled={getErrorFlagState} style={{ cursor: getErrorFlagState ? "not-allowed" : "pointer", backgroundColor: getModeState.backgroundColor, color: getModeState.fontColor }}>
                    <img src={PhotoUploadIcon} className="btn_icon_btn" alt="camera" style={{ background: "transparent" }} />
                    <p className="m-0"> Upload Photo </p>
                  </button>
                </>
              )}
            </ImageUploading>
          </div>
          <div className="d-flex justify-content-center align-items-start">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={getConsentState} onChange={() => handleConcentCheck()} />
              <label className="form-check-label consentText" htmlFor="flexCheckDefault" style={{ color: getModeState.fontColor }}>
                I consent to the capture of my skin image for model training purposes by the Felicita Skin Analyzer. I understand that my images will be stored securely and will not be shared for any other purpose without explicit consent.
              </label>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}
