import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateData, validateEmail, validateName, validatePhone } from '../Utils/Utils';
import bgDark from '../../Images/Blog_page_Login_Screens_Dark.png';
import bgLight from '../../Images/Blog_page_Login_Screens_Light.png';
import felicitaLogoLight from '../../Images/FelicitaLogoLight.svg';
import felicitaLogoDark from '../../Images/felicita.svg';
import { AnalyzerApi } from '../../Network/API';
import '../../css/EventForm.css';

export default function UserInfo(props) {
  const [getMobileValueState, setMobileValueState] = useState();
  const [getFNameState, setFNameState] = useState('');
  const [getLNameState, setLNameState] = useState('');
  const [getCompanyNameState, setCompanyNameState] = useState('');
  const [getEmailValueState, setEmailValueState] = useState('');
  const [getFNameErrorState, setFNameErrorState] = useState('');
  const [getLNameErrorState, setLNameErrorState] = useState('');
  const [getCompanyErrorState, setCompanyErrorState] = useState('null');
  const [getEmailErrorState, setEmailErrorState] = useState(null);
  const [getMobileErrorState, setMobileErrorState] = useState(null);
  const [getUserDataState, setUserDataState] = useState({});
  const navigate = useNavigate();
  let { state } = useLocation();
  if (state) {
    state = JSON.parse(state);
  }

  //Handles User Form Data
  function handleFormData(_event) {
    let temp = getUserDataState;
    if (_event.target.name === 'first_name') {
      setFNameState(_event.target.value);
      validateName(_event.target.value)
    }
    if (_event.target.name === 'last_name') {
      setLNameState(_event.target.value);
      validateName(_event.target.value)
    }
    if (_event.target.name === 'email') {
      setEmailValueState(_event.target.value);
      validateEmail(_event.target.value)
    }
    if (_event.target.name === 'company_name') {
      setCompanyNameState(_event.target.value);
      validateData(_event.target.value);
    }
    temp[_event.target.name] = _event.target.value;
    temp["contact_number"] = getMobileValueState;
    setUserDataState(temp);

  }

  //Posts User Details 
  function handleProceed() {
    let userDatils = new AnalyzerApi();
    userDatils.storeEventUserData(getUserDataState).then((_response) => {
      if (_response.status === 200) {
        props.setUserDetailsFilledState(true)
      }
    });
  }

  useEffect(() => {
    validateName(getFNameState).then((_value) => setFNameErrorState(_value));
    validateName(getLNameState).then((_value) => setLNameErrorState(_value));
    validateEmail(getEmailValueState).then((_value) => setEmailErrorState(_value));
    validatePhone(getMobileValueState).then((_value) => setMobileErrorState(_value));
    validateData(getCompanyNameState).then((_value) => setCompanyErrorState(_value));
  }, [getFNameState, getLNameState, getEmailValueState, getMobileValueState, getCompanyNameState])


  //Handle Unwanted Redirects
  useEffect(() => {
    if (state === null) {
      navigate('/SkinAnalyzer');
    }
    window.addEventListener('popstate', () => {
      navigate('/SkinAnalyzer');
    })
    // eslint-disable-next-line 
  }, []);
  return (
    <>
      <div className="EventBg" style={{ backgroundImage: isMobile ? '' : `url(${props.bodyBackground === "#1E1E1E" ? bgDark : bgLight})` }}>
        <Container className=' mt-5'>
          <Row>
            <Col md={isMobile ? 0 : 5}>
              <Row className='d-flex justify-content-center'>
                <Col md={6}>
                  <div className="d-flex justify-content-center">
                    <img src={props.bodyBackground === "#1E1E1E" ? felicitaLogoDark : felicitaLogoLight} alt="Logo" width="70%" />
                  </div>
                  <Row>
                    <div>
                      <div className="mb-1">
                        <label htmlFor="firstName" className="form-label eventTexts" style={{ color: props?.color }}>First Name</label>
                        <input type="text" className="form-control eventInpts" name="first_name" onChange={(e) => { handleFormData(e) }} style={{ backgroundColor: props?.tileBackground, color: props?.color }} />
                        <div className="d-flex justify-content-end validationTexts">
                          <span className='validationSpan'>{getFNameErrorState}</span>
                        </div>
                      </div>
                      <div className="mb-1">
                        <label htmlFor="lastName" className="form-label eventTexts" style={{ color: props?.color }}>Last Name</label>
                        <input type="text" className="form-control eventInpts" name="last_name" onChange={(e) => { handleFormData(e) }} style={{ backgroundColor: props?.tileBackground, color: props?.color }} />
                        <div className="d-flex justify-content-end validationTexts">
                          <span className='validationSpan'>{getLNameErrorState}</span>
                        </div>
                      </div>
                      <div className="mb-1">
                        <label htmlFor="emailAdd" className="form-label eventTexts" style={{ color: props?.color }}>Email address</label>
                        <input type="email" className="form-control eventInpts" name="email" onChange={(e) => { handleFormData(e) }} style={{ backgroundColor: props?.tileBackground, color: props?.color }} />
                        <div className="d-flex justify-content-end validationTexts">
                          <span className='validationSpan'>{getEmailErrorState}</span>
                        </div>
                      </div>
                      <div className="mb-1">
                        <label htmlFor="contactNum" className="form-label eventTexts" style={{ color: props?.color }}>Contact</label>
                        <div className='eventPhoneInput'>
                          <PhoneInput
                            className='dataInputs'
                            name="mobile"
                            placeholder="Enter Phone Number"
                            limitMaxLength={true}
                            value={getMobileValueState}
                            onChange={setMobileValueState}
                          />
                        </div>
                        <div className="d-flex justify-content-end validationTexts">
                          <span className='validationSpan'>{getMobileErrorState}</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="companyName" className="form-label eventTexts" style={{ color: props?.color }}>Company</label>
                        <input type="text" className="form-control eventInpts" value={getCompanyNameState} name="company_name" onChange={(e) => { handleFormData(e) }} style={{ backgroundColor: props?.tileBackground, color: props?.color }} />
                        <div className="d-flex justify-content-end validationTexts">
                          <span className='validationSpan'>{getCompanyErrorState}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button type="submit" className="btn form-control"
                          disabled={
                            getFNameErrorState === '' &&
                              getLNameErrorState === '' &&
                              getEmailErrorState === '' &&
                              getMobileErrorState === '' &&
                              getCompanyErrorState === ''
                              ? false : true
                          }
                          onClick={() => handleProceed()}
                          style={{ backgroundColor: props.bodyBackground === "#1E1E1E" ? props.tileBackground : 'black', color: 'white' }}
                        >Proceed</button>
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
