import { DeviceUUID } from "device-uuid";
import { v4 as uuidv4 } from 'uuid';
// Local server URL.
// const BASE_URL = "http://192.168.8.51";

// Production server URL. 
const BASE_URL = "https://skinanalyzer.felicita.ai"
function getDeviceUUID() {
  var du = new DeviceUUID().parse();
  var dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen,
  ];
  let uid = du.hashMD5(dua.join(":"));
  return uid;
}



export {
  BASE_URL,
  getDeviceUUID,
  uuidv4
};
