import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './style.css';

export default function CustomToast(props) {
  const [getErrorFlagState, setErrorFlagState] = useState();
  function handleCancelError() {
    setErrorFlagState(false);
    if (props.setErrorFlagState !== undefined) {
      props.setErrorFlagState(false);
    }
  }
  useEffect(() => {
    if (!props.getErrorFlagState) {
      setErrorFlagState([false, '']);
    }
    else {
      setErrorFlagState([props.getErrorFlagState,'']);
    }
  }, [props.getErrorFlagState])


  return (
    <>
      <div className="d-flex justify-content-center align-items-center myClass">
        <Modal show={getErrorFlagState} centered>
          <Modal.Body className='customError d-flex justify-content-center' >
            {props?.message}
          </Modal.Body>
          <div className="d-flex justify-content-center py-1 customError">
            <span className='cancelErrorDiv p-2' onClick={() => handleCancelError()}>
              Ok
            </span>
          </div>
        </Modal>
      </div>
    </>
  )
}
