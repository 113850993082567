import Button from "react-bootstrap/Button";
import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import mainContext from "../../../Context/indexContext";
import "./style.css";
import Form from "react-bootstrap/Form";
import { BlogsApi } from "../../../Network/API";
function SignInToast(props) {
  const navigate = useNavigate();
  const themeContext = useContext(mainContext);

  return (
    <Modal {...props} centered>
      <Modal.Header
        className="d-flex justify-content-center"
        style={{ background: themeContext.getModeState.tileBG }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ background: themeContext.getModeState.tileBG }}
        >
          <h4 className="card-title blog-titles">Kindly Sign In to access.</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer
        className="d-flex justify-content-center"
        style={{ background: themeContext.getModeState.tileBG }}
      >
        <Button onClick={() => navigate("/Blogs/User")}>Sign In</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ReportCommentToast(props) {
  //  FEL-128 Create Componets for Report comments.
  const themeContext = useContext(mainContext);
  const [selectedValue, setSelectedValue] = useState("");
  const blogApi = new BlogsApi();

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const reportComment = () => {
    let data = {};
    let url = null;
    if (props.isReportReplyComment) {
      data = {
        reply_id: props.comment,
        msg: selectedValue,
      };
      url = "/api/v1/report-reply-comment/";
    } else {
      url = "/api/v1/report-comment/";
      data = {
        comment_id: props.comment,
        msg: selectedValue,
      };
    }
    blogApi.reportComment(url, data).then((response) => {
      props.setReportComment(false);
    });
  };
  return (
    <Modal {...props} centered>
      <Modal.Header
        className="d-flex justify-content-center"
        style={{ background: themeContext.getModeState.tileBG }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ background: themeContext.getModeState.tileBG }}
        >
          <h4 className="card-title blog-titles">
            Are you want to report this comments ?
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  style={{
              background: themeContext.getModeState.tileBG,
            }}>
        <Form >
          {["radio"].map((type) => (
            <div key={`Report`} className="mb-3"  
            style={{  color: themeContext.getModeState.color, }}
            >
              <Form.Check
                type={type}
                name="group1"
                label={"Violence"}
                id={`Report`}
                value="Violence" // Add a value prop to each radio button
                checked={selectedValue === "Violence"} // Check if this radio button is selected
                onChange={handleRadioChange} // Attach the change event handler
              />
              <Form.Check
                type={type}
                id={"Report1"}
                name="group1"
                label={`Spam`}
                value="Spam"
                checked={selectedValue === "Spam"}
                onChange={handleRadioChange}
              />
              <Form.Check
                type={type}
                name="group1"
                label={"Illegal"}
                id={`Report2`}
                value="Illegal"
                checked={selectedValue === "Illegal"}
                onChange={handleRadioChange}
              />
              <Form.Check
                type={type}
                name="group1"
                label={"Something else"}
                id={`Report3`}
                value="Something else"
                checked={selectedValue === "Something else"}
                onChange={handleRadioChange}
              />
            </div>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer
        className="d-flex justify-content-center"
        style={{ background: themeContext.getModeState.tileBG }}
      >
        <Button onClick={() => reportComment()}>Report</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default SignInToast;
export { ReportCommentToast };
