import React, { useEffect, useState } from "react";
import { BlogsApi } from "../../../Network/API";
import Reply from "../Reply";
import { getFormattedTime } from "../../../Pages/Utils/Utils";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import SignInToast, { ReportCommentToast } from "../SignInToast";
import { FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import profile from "../../../Images/profile.png";

export default function Comments(props) {
  // State variables
  const SETCHARLIMIT = 200;
  const [commentData, setCommentData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [reportCommentModel, setReportCommentModel] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [ishidden, sethidden] = useState(false);
  const commentCreated = new BlogsApi();
  const [reply, setreply] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isLike, setIsLike] = useState(true);
  const [commentEdit, setCommentEdit] = useState("");
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editHidden, setEditHidden] = useState(false);
  const [maxLimit, setMaxLimit] = useState(SETCHARLIMIT);
  const [maxReplyLimit, setMaxReplyLimit] = useState(SETCHARLIMIT);
  const [maxEditLimit, setMaxEditLimit] = useState(SETCHARLIMIT);
  const [commentId, setCommentId] = useState();
  // Filter comments by "Latest" or "Popular" and update button color
  const filterLatestAndPopulateComments = (value) => {
    props.changeFilter(value);
    setCommentData((prevCommentData) => {
      if (value === "Latest") {
        return [...prevCommentData].sort(
          (a, b) => new Date(b.commented_at) - new Date(a.commented_at)
        );
      } else if (value === "Popular") {
        return [...prevCommentData].sort(
          (a, b) =>
            parseInt(b.comment_total_likes) - parseInt(a.comment_total_likes)
        );
      }
      return prevCommentData;
    });
  };

  // Initial load and comment filtering effect
  useEffect(() => {
    setCommentData(props.comments);
    if (props.filter === "Latest" || props.filter === "Popular") {
      filterLatestAndPopulateComments(props.filter);
    }
  }, [props.comments]);

  // Add a new comment
  const addCommentHandler = () => {
    commentCreated
      .addGeneralLikesAndComment("/api/v1/comments/", {
        blog_id: props.blog_id,
        comment: newComment,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
    setNewComment("");
    setMaxLimit(SETCHARLIMIT);
  };

  // Like a comment
  const likeComment = (id) => {
    setIsLike(!isLike);
    commentCreated
      .addGeneralLikesAndComment("/api/v1/comment-likes/", {
        comment_id: id,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
  };

  // Add a reply to a comment
  const replyCommentAdd = (id) => {
    commentCreated
      .addGeneralLikesAndComment("/api/v1/replies-comment/", {
        comment_id: id,
        reply: replyComment,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
    sethidden(false);
    setReplyComment("");
  };

  // Show/hide reply input
  const getComment = (id) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setreply(id);
    sethidden(!ishidden);
    setEdit(false);
  };

  // Edit a comment
  const editComment = (id, comment) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setEditHidden(!editHidden);
    setEdit(id);
    setreply(false);
    setCommentEdit(comment);
  };

  // Save edited comment
  const editComments = (id) => {
    commentCreated
      .editComment(`/api/v1/comments/${id}/`, {
        comment: commentEdit,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
    setEditHidden(!editHidden);
  };

  //  FEL-128 Report Comment API.
  const reportComment = (id) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setReportCommentModel(true);
    setCommentId(id);
  };

  // Close modal and delete comment
  const handleClose = () => {
    setShow(false);
    commentCreated
      .deleteComment(`/api/v1/comments/${deleteId}/`)
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
  };

  const handleDeleteComment = (id) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setDeleteId(id);
    setShow(true);
  };

  const charLimitAnd = (value) => {
    setNewComment(value);
    setMaxLimit(SETCHARLIMIT - value.length);
  };

  const handleMaxReplyLimit = (value) => {
    setReplyComment(value);
    setMaxReplyLimit(SETCHARLIMIT - value.length);
  };

  const handleEditMaxLimit = (value) => {
    setCommentEdit(value);
    setMaxEditLimit(SETCHARLIMIT - value.length);
  };

  return (
    <>
      {showErrorModal && (
        <SignInToast
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
        />
      )}
      {/* FEL-128 Added the report Comment. */}
      {reportCommentModel && (
        <ReportCommentToast
          show={reportCommentModel}
          onHide={() => setReportCommentModel(false)}
          comment={commentId}
          setReportComment={setReportCommentModel}
          isReportReplyComment={false}
        />
      )}
      <Row>
        <Col
          className={`d-flex align-items-center ${
            isMobile ? "justify-content-center" : ""
          }`}
        >
          <h4 className="comment_color">Comment</h4>
        </Col>
        <div className={`mb-3 ${!isMobile ? "row" : ""}`}>
          <Col
            className={
              isMobile
                ? `d-flex justify-content-center`
                : "d-flex justify-content-end"
            }
          >
            <p
              className="m-1 "
              style={{
                color: props.theme.getModeState.color,
              }}
            >
              {maxLimit} / {SETCHARLIMIT}
            </p>
            <button
              className={
                props.filter === "Latest"
                  ? "lastest border mx-1"
                  : "populate border mx-1"
              }
              onClick={() => filterLatestAndPopulateComments("Latest")}
            >
              Latest
            </button>
            <button
              className={
                props.filter === "Latest"
                  ? "populate border mx-1"
                  : props.filter == ""
                  ? "populate border mx-1"
                  : "lastest border mx-1"
              }
              onClick={() => filterLatestAndPopulateComments("Popular")}
            >
              Popular
            </button>
          </Col>
        </div>
        <div className="mb-2">
          <textarea
            className="form-control mb-3"
            rows="7"
            value={newComment}
            onChange={(event) => charLimitAnd(event.target.value)}
            maxLength={SETCHARLIMIT}
            placeholder="Hi! Write your comment here."
            style={{
              backgroundColor: props.theme.getModeState.tileBG,
              color: props.theme.getModeState.color,
            }}
          />
        </div>
      </Row>
      <div className={isMobile ? "d-flex justify-content-center" : ""}>
        <button className="border send" onClick={addCommentHandler}>
          Send
        </button>
      </div>

      <div className="comment-container">
        {commentData?.length > 0 &&
          commentData.map((comment, key) => {
            return (
              <div className="row" key={key}>
                <div className="">
                  <div>
                    <div className="d-flex">
                      <div>
                       <img
                          className="profileImage mr-0"
                          src={comment.author.photo || profile}
                        />
                      </div>
                      <div className="d-flex ">
                        <span className="my-auto text-capitalize">
                          <b
                            className="text-capitalize"
                            style={{ color: props.theme.getModeState.color }}
                          >
                            {comment.author.first_name}{" "}
                            {comment.author.last_name}
                          </b>
                        </span>
                        <span
                          style={{ wordWrap: "break-word" }}
                          className="mx-3 text-secondary my-auto align-items-center"
                        >
                          {getFormattedTime(comment.commented_at)} {"  "}{" "}
                          <span className="badge rounded-pill bg-secondary">
                            {comment.edited && "Edited"}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="mx-5 ml-0 mb-3 w-100">
                      <p
                        style={{
                          color: props.theme.getModeState.color,
                          wordWrap: "break-word",
                        }}
                      >
                        {comment.comment}
                      </p>
                    </div>
                    <div
                      className="mx-5 ml-0"
                      style={{
                        color: props.theme.getModeState.color,
                      }}
                    >
                      <a onClick={() => likeComment(comment.id)}>
                        {!comment.user_liked ? (
                          <FaRegThumbsUp
                            fill={props.theme.getModeState.color}
                            className="custom-icon"
                            role="button"
                          />
                        ) : (
                          <FaThumbsUp
                            fill="#ff0000"
                            className="custom-icon"
                            role="button"
                          />
                        )}
                      </a>
                      <span className="mr-2">
                        {"  "}
                        {comment.comment_total_likes}
                      </span>
                      <span
                        className="mx-2 fa-icon"
                        onClick={() => getComment(comment.id)}
                        role="button"
                        style={{
                          color: props.theme.getModeState.color,
                        }}
                      >
                        Reply
                      </span>
                      {comment.is_commenter == true && (
                        <>
                          <span
                            className="mx-1 fa-icon"
                            role="button"
                            onClick={() =>
                              editComment(comment.id, comment.comment)
                            }
                            style={{
                              color: props.theme.getModeState.color,
                            }}
                          >
                            Edit
                          </span>
                          <span
                            className="mx-1 fa-icon"
                            role="button"
                            onClick={() => handleDeleteComment(comment.id)}
                            style={{
                              color: props.theme.getModeState.color,
                            }}
                          >
                            Delete
                          </span>
                        </>
                      )}
                      <span
                        className="mx-1 fa-icon"
                        role="button"
                        onClick={() => reportComment(comment.id)}
                        style={{
                          color: props.theme.getModeState.color,
                        }}
                      >
                        Report
                      </span>
                    </div>
                    <div className="reply-div mx-5 ml-0">
                      {reply === comment.id && ishidden && (
                        <div className="mb-2">
                          <p
                            className="m-2 float-end"
                            style={{
                              color: props.theme.getModeState.color,
                            }}
                          >
                            {" "}
                            {maxReplyLimit} / {SETCHARLIMIT}
                          </p>
                          <textarea
                            className="form-control mb-2"
                            rows="3"
                            onChange={(event) =>
                              handleMaxReplyLimit(event.target.value)
                            }
                            maxLength={SETCHARLIMIT}
                            placeholder="Hi Write your Comment here"
                            style={{
                              backgroundColor: props.theme.getModeState.tileBG,
                              color: props.theme.getModeState.color,
                            }}
                          />
                          <button
                            className="border send"
                            onClick={() => replyCommentAdd(comment.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="reply-div mx-5 ml-0">
                      {edit === comment.id && editHidden && (
                        <div className="mb-2">
                          <p
                            className="m-2 float-end"
                            style={{
                              color: props.theme.getModeState.color,
                            }}
                          >
                            {maxEditLimit} / {SETCHARLIMIT}
                          </p>
                          <textarea
                            className="form-control mb-2"
                            rows="3"
                            maxLength={SETCHARLIMIT}
                            value={commentEdit}
                            onChange={(event) => {
                              handleEditMaxLimit(event.target.value);
                            }}
                            placeholder="Hi Edit your comment here."
                            style={{
                              backgroundColor: props.theme.getModeState.tileBG,
                              color: props.theme.getModeState.color,
                            }}
                          />
                          <button
                            className="border send"
                            onClick={() => editComments(comment.id)}
                          >
                            Send
                          </button>
                        </div>
                      )}
                    </div>
                    <Modal show={show} onHide={() => setShow(false)} centered>
                      <Modal.Header className="d-flex justify-content-center"  style={{
                            backgroundColor: props.theme.getModeState.tileBG,
                            color: props.theme.getModeState.color,
                          }}>
                        <Modal.Title
                         
                        >
                          Do you want to delete this comment?
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Footer className="d-flex justify-content-center"  style={{
                            backgroundColor: props.theme.getModeState.tileBG,
                            color: props.theme.getModeState.color,
                          }}>
                        <Button variant="primary" onClick={() => handleClose()}>
                          Confirm
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => setShow(false)}
                        >
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Reply
                      replies={comment.replies}
                      getApiData={props.getApiData}
                      theme={props.theme}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
