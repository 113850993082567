import React, { useEffect } from "react";
import BlogsLayout from "../Layouts/BlogsLayout";
import Articles from "../../Components/BlogsComponents/Articles";

export default function BlogContent() {
  return <>
      <div>
        <BlogsLayout inner={Articles}  />
      </div>
    </>
}