import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { PiNotebookBold, PiNumberOneBold, PiNumberThreeBold, PiNumberTwoBold } from 'react-icons/pi';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';
import { isMobile } from 'react-device-detect';
import felicitaLogoDark from "../../../Images/felicita.svg";
import correct1 from "../../../Images/CorrectGuide1.svg";
import correct2 from "../../../Images/CorrectGuide2.svg";
import correct3 from "../../../Images/CorrectGuide3.svg";
import incorrect1 from "../../../Images/InCorrectGuide1.svg";
import incorrect2 from "../../../Images/InCorrectGuide2.svg";
import incorrect3 from "../../../Images/InCorrectGuide3.svg";
import refImg from "../../../Images/RefrenceLines.svg";
import './style.css';

export default function Header(props) {
    const [getModeState, setModeState] = useState(false);
    const [getThemeState, setThemeState] = useState({});
    const [getGuidelineState, setGuidelineState] = useState(false);
    let getThemeData = localStorage.getItem('mode');

    //Handle Theme Inintialization
    function initTheme() {
        setThemeState({
            fontColor: '#000000',
            backgroundColor: '#ECEEF5',
            tileBG: '#FFFFFF',
            tileFont: '#000000',
            mode: false
        });
        setTimeout(() => {
            localStorage.setItem('mode', JSON.stringify(getThemeState));
        }, 200);
        document.body.style.backgroundColor = getThemeState.backgroundColor;
    }

    //FEL-98,FEL-100 To update toggle mode state
    function toggleThemeMode() {
        setModeState(!getModeState);
    }

    function toggleGuide() {
        setGuidelineState(!getGuidelineState);
    }
    //FEL-98,FEL-100 Added Useeffect on toggle change 
    useEffect(() => {
        if (getModeState) {
            setThemeState({
                fontColor: '#ffffff',
                backgroundColor: '#1E1E1E',
                tileBG: '#1E2029',
                tileFont: '#FFFFFF',
                mode: true
            });
        }
        else {
            setThemeState({
                fontColor: '#000000',
                backgroundColor: '#ECEEF5',
                tileBG: '#FFFFFF',
                tileFont: '#000000',
                mode: false
            });
        }
        localStorage.setItem('mode', JSON.stringify(getThemeState));
        // eslint-disable-next-line
    }, [getModeState]);

    //FEL-98,FEL-100
    useEffect(() => {
        if (props.setTheme) {
            props.setTheme(getThemeState)
        }
        localStorage.setItem('mode', JSON.stringify(getThemeState));
        document.body.style.backgroundColor = getThemeState.backgroundColor;
        // eslint-disable-next-line
    }, [getThemeState])

    useEffect(() => {
        if (props.getFirstViewedGuidelinesState) {
            toggleGuide();
        }
        // eslint-disable-next-line
    }, [props.getFirstViewedGuidelinesState])

    useEffect(() => {
        if (props.getFirstViewedGuidelinesState && !getGuidelineState) {
            props.setFirstViewedGuidelinesCheckedState(true)
        }
        // eslint-disable-next-line
    }, [getGuidelineState])

    useEffect(() => {
        if (!getThemeData) {
            initTheme();
        }
        else if (getThemeData) {
            setThemeState(JSON.parse(getThemeData));
            if (JSON.parse(getThemeData).mode) {
                setModeState(true);
            }
            document.body.style.backgroundColor = getThemeState.backgroundColor;
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className={`headerMain ${isMobile ? '' : 'row'}`}>
                <Col md={4} className='d-flex justify-content-center align-items-center'>
                    <div>
                        <div className="d-flex justify-content-center p-2">
                            <img src={felicitaLogoDark} width='40%' alt="Logo" />
                        </div>
                    </div>
                </Col>
                <Col md={4} className='d-flex justify-content-center align-items-center'>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <span className='welcomeHeader'>{props.welcomeText}</span><span className='skinHeader'>&nbsp;Skin Analyzer</span>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <span className='headerDesc'>{props.descText}</span>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={`d-flex justify-content-center align-items-center ${isMobile ? 'mt-2' : ''}`}>
                    <Row className={isMobile ? 'mb-2' : ''}>
                        <Col md={6} xs={6}>
                            <div className="modeIconBackdrop d-flex justify-content-center modeDiv " onClick={() => toggleGuide()}>
                                <PiNotebookBold fill="#000000" size={25} />
                            </div>
                        </Col>
                        <Col md={6} xs={6}>
                            <div className='modeDiv' onClick={() => toggleThemeMode()}>
                                <div className="modeIconBackdrop d-flex justify-content-center ">
                                    {getModeState ?

                                        <MdLightMode fill='#000000' size={25} />
                                        :
                                        <MdDarkMode fill="#000000" size={25} />
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Modal show={getGuidelineState} className='guideLInesModal' centered >
                    <Modal.Header onClick={() => toggleGuide()} style={{ backgroundColor: getThemeState.tileBG }}>
                        <span style={{ color: getThemeState.fontColor }}>Your all result history</span>
                        {getThemeState.mode ?
                            <ImCross fill='#ffffff' size={25} className='p-1' style={{ cursor: 'pointer' }} />
                            :
                            <ImCross fill='#000000' size={25} className='p-1' style={{ cursor: 'pointer' }} />
                        }
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: getThemeState.tileBG }}>
                        <Row>
                            <Col md={6}>
                                <div className="d-flex justify-content-center">
                                    <div className="p-1" >
                                        <img src={correct1} alt="guideImg" width={92} height={138} className='guideLineImage' style={{ position: 'absolute' }} />
                                        <img src={refImg} alt="guideImg" width={92} height={138} className='guideLineImage' style={{ position: 'relative', zIndex: '1' }} />
                                        <div className="numberbackDrop" style={{ position: 'absolute', zIndex: '1', top: '25px' }} >
                                            <PiNumberOneBold fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                        <div className="tickbackDrop" style={{ position: 'absolute', zIndex: '1', top: '7.7rem', left: '4.5rem' }} >
                                            <TiTick fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <img src={correct2} alt="guideImg" width={92} height={138} className='guideLineImage' />
                                        <div className="numberbackDrop" style={{ position: 'absolute', zIndex: '1', top: '25px' }} >
                                            <PiNumberTwoBold fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                        <div className="tickbackDrop" style={{ position: 'absolute', zIndex: '1', top: '7.7rem', left: '10.5rem' }} >
                                            <TiTick fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <img src={correct3} alt="guideImg" width={92} height={138} className='guideLineImage' />
                                        <div className="numberbackDrop" style={{ position: 'absolute', zIndex: '1', top: '25px' }} >
                                            <PiNumberThreeBold fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                        <div className="tickbackDrop" style={{ position: 'absolute', zIndex: '1', top: '7.7rem', left: '17.0rem' }} >
                                            <TiTick fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className='d-flex align-items-center'>
                                <div className="d-flex justify-content-center">
                                    <ol>
                                        <li style={{ color: getThemeState.fontColor }}>Ensure face position.</li>
                                        <li style={{ color: getThemeState.fontColor }}>Ensure proper ligting.</li>
                                        <li style={{ color: getThemeState.fontColor }}>Straight face is a good choice.</li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="d-flex justify-content-center">
                                    <div className="p-1">
                                        <img src={incorrect1} alt="guideImg" width={92} height={138} className='guideLineImage' />
                                        <div className="numberbackDrop" style={{ position: 'absolute', zIndex: '1', top: '11rem' }} >
                                            <PiNumberOneBold fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                        <div className="crossbackDrop" style={{ position: 'absolute', zIndex: '1', top: '17rem', left: '4.5rem' }} >
                                            <ImCross fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <img src={incorrect2} alt="guideImg" width={92} height={138} className='guideLineImage' style={{ position: 'absolute' }} />
                                        <img src={refImg} alt="guideImg" width={92} height={138} className='guideLineImage' style={{ position: 'relative', zIndex: '1' }} />
                                        <div className="numberbackDrop" style={{ position: 'absolute', zIndex: '1', top: '11rem' }} >
                                            <PiNumberTwoBold fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                        <div className="crossbackDrop" style={{ position: 'absolute', zIndex: '1', top: '17rem', left: '10.5rem' }} >
                                            <ImCross fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <img src={incorrect3} alt="guideImg" width={92} height={138} className='guideLineImage' style={{ position: 'absolute' }} />
                                        <img src={refImg} alt="guideImg" width={92} height={138} className='guideLineImage' style={{ position: 'relative', zIndex: '1' }} />
                                        <div className="numberbackDrop" style={{ position: 'absolute', zIndex: '1', top: '11rem' }} >
                                            <PiNumberThreeBold fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                        <div className="crossbackDrop" style={{ position: 'absolute', zIndex: '1', top: '17rem', left: '17.0rem' }} >
                                            <ImCross fill='#ffffff' size={25} className='p-1' />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className='d-flex align-items-center'>
                                <div className="d-flex justify-content-center">
                                    <ol>
                                        <li style={{ color: getThemeState.fontColor }}>Avoid tilted face.</li>
                                        <li style={{ color: getThemeState.fontColor }}>Avoid closeup face.</li>
                                        <li style={{ color: getThemeState.fontColor }}>Avoid too zoomed out face.</li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    )
}
