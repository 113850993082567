import moment from "moment";
const WORDS_PER_MIN = 200;
const getFormattedTime = (commented_at) => {
    return moment.utc(commented_at).local().startOf("seconds").fromNow();
};

const timestrampToDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
};

const esstimatedTimeToRead = (data) => {
    if (data === undefined) {
        return ""
    }
    let removeHtmlAndCountWords = data
        .replace(/<[^>]*>/g, "")
        .trim()
        .split(/\s+/).length;
    return Math.ceil(removeHtmlAndCountWords / WORDS_PER_MIN);
};

//Returns Formated Text
function textFormating(_data) {
    let value = _data.split('_').map((_element) => _element.charAt(0).toUpperCase() + _element.slice(1)).join(' ');
    return value;
}

//Returns Valid Email Or Not
async function validateEmail(_email) {
    let returnValue;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((!emailRegex.test(_email)) || _email.length > 50) {
        returnValue = 'Invalid Email';
    } else {
        returnValue = '';
    }
    return returnValue;
}

//Returns Valid Phone Or Not
async function validatePhone(_data) {
    let returnValue;
    if (_data !== undefined) {
        if (_data.trim().length === 12 || _data.trim().length === 13 || _data.trim().length === 14) {
            returnValue = '';
        } else {
            returnValue = 'Invalid Number';
        }
    } else {
        returnValue = 'Invalid Number';
    }
    return returnValue
}

//Returns Valid Name Inputs
async function validateName(_data) {
    let returnValue;
    const re = /^[A-Za-z]+$/;
    if (((!re.test(_data) && _data.length > 0) || _data === '' || _data.length > 20)) {
        returnValue = 'Invalid Name'
    } else {
        returnValue = '';
    }
    return returnValue;
}
//Returns A Valid String
async function validateData(_data) {
    let returnValue;
    const re = /^[A-Za-z]+$/;
    if (((!re.test(_data) && _data.length > 0) || _data === '' || _data.length > 30)) {
        returnValue = 'Invalid'
    } else {
        returnValue = '';
    }
    return returnValue;
}

//Returns RGB from HEX
function hexToRgb(_hexValue) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hexValue);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}


export { getFormattedTime, timestrampToDate, esstimatedTimeToRead, textFormating, validateEmail, validatePhone, validateName, hexToRgb, validateData }