import React, { useState } from 'react'
import "./style.css"
import { Container } from 'react-bootstrap'
import Webcam from 'react-webcam'
import { useRef, useEffect } from 'react'
import { FaceMesh, FACEMESH_LEFT_IRIS } from '@mediapipe/face_mesh'
import * as cam from '@mediapipe/camera_utils'
import face_box from '../../Images/Face_reference.png';
import pre_Loader from '../../Images/Preloader.gif';
import { GrStatusGood } from 'react-icons/gr';
import { GiCancel } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom'
import { RxCross1 } from 'react-icons/rx'
import { isMobile } from 'react-device-detect'

export default function CameraMobile(props) {
  const [getBrightnessState, setBrightnessState] = useState([0, 'Not Good', '#FF0000']);
  const [getLookStraightState, setLookStraightState] = useState([50, 'Ok', '#FFF500']);
  const [getFacePositionState, setFacePositionState] = useState([100, 'Good', '#38D800']);
  const [getCapturedImage, setCapturedImage] = useState('');
  const [counter, setCounter] = useState(4);
  const [getAllFlag, setAllFlag] = useState(false)
  const [getRejectedState, setRejectedState] = useState(false);
  const [getZState, setZState] = useState(0);
  const [getConfirmState, setConfirmState] = useState('none');
  const [getConfirmStateInverse, setConfirmStateInverse] = useState('');
  const [getDisplayLoaderState, setDisplayLoaderState] = useState(['none', 'blur(0px)']);
  const videoConstraints = {
    facingMode: "user"
  };


  function initState() {
    setBrightnessState([0, 'Not Good', '#FF0000']);
    setLookStraightState([50, 'Ok', '#FFF500']);
    setFacePositionState([100, 'Good', '#38D800']);
    setCapturedImage('');
    setCounter(4);
    setAllFlag(false);
    setRejectedState(false);
    setZState(0);
    setConfirmState('none');
    setConfirmStateInverse('');
    setDisplayLoaderState(['none', 'blur(0px)']);
  }

  let lightFlag = 0;
  let lookFlag = 0;
  let faceFlag = 0;

  let setBrightness;
  let setFacePosition;
  let getLandmarks;
  let getResults;
  let loaderHeight = '100%';
  let canvasElement;
  let canvasWithImgElement;
  let canvasWithImgCtx;
  let canvasCtx;
  let imageData;

  let imageFrame = document.getElementsByClassName('output_canvasMobile');

  //Image Tag for Face reference image to be drawn on canvas
  const Imagetag = document.createElement('img');
  Imagetag.height = 100;
  Imagetag.width = 100;
  Imagetag.src = face_box;
  const navigate = useNavigate();

  const webcamRef = useRef(null)
  const canvasRef = useRef(null)
  const canvasWithImage = useRef(null)
  const A_name = useRef('');

  async function sendImages(_base64) {
    let getScoreCatrgoryState = props.scoreCategory;
    let getSkinIssueState = props.allIssues;
    setDisplayLoaderState(['flex', 'blur(8px)']);
    setTimeout(() => {
      setDisplayLoaderState(['none', 'blur(0px)']);
      navigate("/SkinAnalyzer/Layout", { replace: true, state: JSON.stringify({ getScoreCatrgoryState, getSkinIssueState, _base64 }) });
    }, 3000);
  }


  //Brightness Calculation
  function calculateBrightness(_imageData) {
    let sum = 0;
    for (let i = 0; i < _imageData.length; i += 4) {
      const r = _imageData[i];
      const g = _imageData[i + 1];
      const b = _imageData[i + 2];
      sum += 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }
    const brightness = sum / (_imageData.length / 4);
    setBrightness = brightness;
    return brightness;//160-170 (Range of Lightening)
  }

  //Look Straight Calculation
  function calculateLookStraight(_landmarks) {

    if ((_landmarks[123].z > 0.07 || _landmarks[123].z < 0.01) || (_landmarks[352].z > 0.06 || _landmarks[352].z < 0.01) || (_landmarks[152].z > 0.04 || _landmarks[152].z < -0.02)) {
      setLookStraightState([0, 'Not Good', '#FF0000']);
      A_name.current = ""
      setZState(0);
      setCounter(3);
      setAllFlag(false);
      lookFlag = -1
    }
    else if (((_landmarks[123].z > 0.05 && _landmarks[123].z < 0.06) || (_landmarks[123].z > 0.01 && _landmarks[123].z < 0.02)) || ((_landmarks[352].z > 0.05 && _landmarks[352].z < 0.06) || (_landmarks[352].z > 0.01 && _landmarks[352].z < 0.02)) || (_landmarks[152].z > 0.04 || _landmarks[152].z < -0.02)) {
      setLookStraightState([50, 'Ok', '#FFF500']);
      lookFlag = 1;
    }
    else {
      setLookStraightState([100, 'Good', '#38D800']);
      lookFlag = 1;
    }
  }


  //Face Position Calculation
  function calculateFacePosition(_landmarks, _results) {
    var irisLeftMinX = -1;
    var irisLeftMaxX = -1;
    var width = _results.image.width;
    var height = _results.image.height;
    for (const point of FACEMESH_LEFT_IRIS) {
      var point0 = _landmarks[point[0]];
      if (irisLeftMinX === -1 || point0.x * width < irisLeftMinX) {
        irisLeftMinX = point0.x * width;
      }
      if (irisLeftMaxX === -1 || point0.x * width > irisLeftMaxX) {
        irisLeftMaxX = point0.x * width;
      }
    }

    var dx = irisLeftMaxX - irisLeftMinX;
    var dX = 11.7;

    var normalizedFocaleX = 1.40625;
    var fx = Math.min(width, height) * normalizedFocaleX;
    var dZ = (fx * (dX / dx)) / 10.0;
    dZ = dZ.toFixed(2);
    setFacePosition = dZ;
    return dZ;//43-46 (Range of Face Position)
  }

  function handleProcced() {
    sendImages(getCapturedImage.split(',')[1])
  }

  function handleReject() {
    setRejectedState(true);
  }
  function handleExit() {
    navigate("/SkinAnalyzer");
    window.location.reload();
  }

  // Stop the camera stream
  function stopCamera() {
    const video = document.getElementById("reactWebcam")
    const stream = video.srcObject
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
  }

  const getImageBase64 = (_canvasCtx, _canvasWithImgCtx, _canvasElement, _canvasWithImgElement, _getResults) => {
    _canvasWithImgCtx.clearRect(0, 0, _canvasWithImgElement.width, _canvasWithImgElement.height);
    _canvasCtx.drawImage(_getResults.image, 0, 0, _canvasElement.width, _canvasElement.height);
    const canvas = imageFrame[0];
    const link = document.createElement('a');
    link.download = 'canvas.jpg';
    link.href = canvas.toDataURL('image/jpeg', 0.8);
    setZState(0);
    setConfirmState('flex');
    setConfirmStateInverse('none');
    setCapturedImage('' + link.href);
    stopCamera();
    _canvasCtx.drawImage(link.href, 0, 0, _canvasElement.width, _canvasElement.height);
    return link.href;
  };
  function handleCameraPermissionRejection() {
    alert('Some features of the app may not work properly if the permission to use camera is not allowed. Please allow the app to use the camera.');
    navigate('/SkinAnalyzer');
  }

  function onResults(results) {
    //set the height and width of canvas
    getResults = results;

    canvasRef.current.width = webcamRef.current.video.videoWidth;
    canvasRef.current.height = webcamRef.current.video.videoHeight;
    canvasWithImage.current.width = webcamRef.current.video.videoWidth;
    canvasWithImage.current.height = webcamRef.current.video.videoHeight;

    canvasElement = canvasRef.current;
    canvasWithImgElement = canvasRef.current;
    canvasWithImgCtx = canvasWithImgElement.getContext('2d');
    canvasCtx = canvasElement.getContext('2d');

    canvasCtx.save();
    canvasWithImgCtx.save();

    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasWithImgCtx.clearRect(0, 0, canvasWithImgElement.width, canvasWithImgElement.height);

    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
    canvasWithImgCtx.drawImage(Imagetag, 0, 0, canvasWithImgElement.width, canvasWithImgElement.height);

    imageData = canvasCtx.getImageData(0, 0, canvasElement.width, canvasElement.height);

    if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
      for (const landmarks of results.multiFaceLandmarks) {
        getLandmarks = landmarks;
      }

      if ((lightFlag === 1) && (lookFlag === 1) && (faceFlag === 1)) {
        setZState(1);
        setAllFlag(true);
      }





      calculateBrightness(imageData.data);
      calculateLookStraight(getLandmarks);
      calculateFacePosition(getLandmarks, getResults);

      //Lighting Check
      if (setBrightness > 150 && setBrightness < 165) {
        setBrightnessState([50, 'Ok', '#FFF500']);
        lightFlag = 1;
      }
      else if (setBrightness > 165 && setBrightness < 215) {
        setBrightnessState([100, 'Good', '#38D800']);
        lightFlag = 1;
      }
      else if (setBrightness > 215 || setBrightness < 150) {
        setBrightnessState([0, 'Not Good', '#FF0000']);
        lightFlag = -1;
        A_name.current = ""
        setZState(0);
        setCounter(3);
        setAllFlag(false);
      }


      //Face Position Check
      if (setFacePosition > 42 && setFacePosition < 50) {
        setFacePositionState([100, 'Good', '#38D800']);
        faceFlag = 1;
      }
      else if (setFacePosition > 50 || setFacePosition < 42) {
        setFacePositionState([0, 'Not Good', '#FF0000']);
        faceFlag = -1;
        A_name.current = ""
        setZState(0);
        setCounter(3);
        setAllFlag(false);
      }

      if (A_name.current === "1") {
        getImageBase64(canvasCtx, canvasWithImgCtx, canvasElement, canvasWithImgElement, getResults);
        lightFlag = 0;
        lookFlag = 0;
        faceFlag = 0;
      }
    }
    else {
      console.log("Face Not found");
    }
  }

  useEffect(() => {
    initState();
    var camera = null
    const faceMesh = new FaceMesh({
      locateFile: file => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
      }
    })

    faceMesh.setOptions({
      selfieMode: true,
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5
    })
    faceMesh.onResults(onResults)
    if (
      typeof webcamRef.current !== 'undefined' &&
      webcamRef.current !== null
    ) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await faceMesh.send({ image: webcamRef.current.video })
        },
        width: 1920,
        height: 1080
      })
      camera.start()
    }
    // eslint-disable-next-line
  }, [getRejectedState])

  useEffect(() => {
    // eslint-disable-next-line
  }, [getBrightnessState, getLookStraightState, getFacePositionState])

  useEffect(() => {
    counter > 0 && setTimeout(() => {
      setCounter(counter - 1)
      if (counter === 1) {
        A_name.current = "1"
      }
    }, 1000);
    // eslint-disable-next-line
  }, [getAllFlag && counter]);
  if (isMobile) {
    loaderHeight = ''
  }

  return (
    <>
      <div className='App'>
        <Webcam
          ref={webcamRef}
          id='reactWebcam'
          videoConstraints={videoConstraints}
          onUserMediaError={handleCameraPermissionRejection}
          style={{
            display: 'none',
            transform: 'rotateY(180deg)'
          }}
        />
      </div>
      <Container className="d-flex justify-content-center ">
        <div style={{ zIndex: `${getZState}` }} className="countDownTimer d-flex justify-content-center" >{counter}</div>
        <RxCross1 className="ExitbuttonMobile" fill="red" onClick={handleExit} />
        <div className="CameraViewMainMobile mt-1">
          <canvas ref={canvasRef} className='output_canvasMobile' style={{ filter: `${getDisplayLoaderState[1]}` }}></canvas>
          <canvas ref={canvasWithImage} className='gl_Canvas'></canvas>
          <img src={pre_Loader} alt="Loader" style={{ display: `${getDisplayLoaderState[0]}`, position: 'absolute', height: loaderHeight, width: '100%', top: '0' }} className="justify-content-center" />
          <div className="justify-content-center" style={{ display: `${getConfirmState}` }}>
            <div className="d-flex justify-content-center  mt-2 ">
              <div className="d-flex justify-content-center align-items-center mx-3 confirmationDivMob bor" onClick={handleReject} style={{ cursor: 'pointer' }}>
                <span className='bor mx-1 my-2'><GiCancel /><br />
                </span>
                <span className='styleScore mx-1'>Retake</span>
              </div>
              <div className="d-flex justify-content-center align-items-center mx-3 confirmationDivMob bor" onClick={handleProcced} style={{ cursor: 'pointer' }}>
                <span className='bor mx-1 my-2'><GrStatusGood /><br />
                </span>
                <span className='styleScore mx-1'>Proceed</span>
              </div>
            </div>
          </div>
          <div className="" style={{ display: `${getConfirmStateInverse}` }}>
            <div className="d-flex justify-content-center  mt-2 ">
              <div className="d-flex justify-content-center w-75 btnLightMobile px-2" style={{ backgroundColor: `${getBrightnessState[2]}` }}>
                <p className='p-0 m-0'>Lighting
                  <br />
                  <span className='lightScoreMobile styleScore d-flex justify-content-center'><b> {getBrightnessState[1]}</b></span>
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center  mt-2 ">
              <div className="d-flex justify-content-center w-75 btnLookMobile px-2" style={{ backgroundColor: `${getLookStraightState[2]}` }}>
                <p className='p-0 m-0'>Look Straight
                  <br />
                  <span className='lookScoreMobile styleScore d-flex justify-content-center'><b> {getLookStraightState[1]}</b></span>
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center  mt-2 ">
              <div className="d-flex justify-content-center w-75 btnFaceMobile px-2" style={{ backgroundColor: `${getFacePositionState[2]}` }}>
                <p className='p-0 m-0'>Face Position
                  <br />
                  <span className='faceScoreMobile styleScore d-flex justify-content-center'><b> {getFacePositionState[1]}</b></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
