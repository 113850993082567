import { BASE_URL, getDeviceUUID, uuidv4 } from "../Pages/Config/Config.js";
import axios from "axios";
let sessionUuid = uuidv4();

export class AnalyzerApi {
  //Get All Score Categories
  async getScoreData() {
    let scoreCategory = null;
    await axios
      .get(`${BASE_URL}/api/v1/score-category/`)
      .then((_response) => {
        scoreCategory = _response.data.data;
      })
      .catch((error) => {
        scoreCategory = 500;
      });
    return scoreCategory;
  }

  //Get All Skin Issues
  async getAllIssues() {
    let skinIssue = null;
    await axios
      .get(`${BASE_URL}/api/v1/skin-issues/`)
      .then((_response) => {
        skinIssue = _response.data.data;
      })
      .catch((error) => {
        skinIssue = 500;
      });
    return skinIssue;
  }

  //Get All Analyzed Data
  async getIssueData(_image, _issue) {
    var device_uuid = getDeviceUUID();

    let issueData = null;
    let image_consent = localStorage.getItem('consent');
    const data = {
      user_uuid: device_uuid,
      image: _image,
      source: "WEB_PORTAL",
      session_uuid: sessionUuid,
      image_consent: image_consent
    };
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(`${BASE_URL}/api/v1/skin-analysis/?skin_issue=${_issue}`, data, {
        headers: headers,
      })
      .then((_response) => {
        issueData = _response.data;
        // start HERE
      })
      .catch((error) => {
        issueData = error.response;
      });
    return issueData;
  }

  //Get Data For Tips And Remedies
  async getTipsRemediesData(_url, _issue, _category, _level) {
    let temp = _issue.split(" ");
    if (temp.length > 1) {
      let check = temp.join("_");
      _issue = check;
    }
    let resultedData = null;
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .get(
        // FEL-247 Updated due to new enpoint
        `${BASE_URL}/api/v1/` + _url + `/?skin_issue=` + _issue.toLowerCase() + `&score_category=` + _category.toLowerCase() + `&type=` + _level.toLowerCase() + ``,
        {
          headers: headers,
        }
      )
      .then((_response) => {
        resultedData = _response.data.data;
      })
      .catch((error) => {
        resultedData = 500;
      });
    return resultedData;
  }

  //Check If Event Exists Or Not
  async checkEventMode() {
    let resultedData = null;
    await axios
      .get(`${BASE_URL}/api/v1/verify_mode/`)
      .then((_response) => {
        resultedData = _response.data;
      })
      .catch((error) => {
        resultedData = 500;
      });
    return resultedData;
  }

  //Store Event's User Details
  async storeEventUserData(_userDetails) {
    let resultedData = null;
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(`https://dev.selfstylo.com/api/v1/visitor-details/`, _userDetails, {
        headers: headers,
      })
      .then((_response) => {
        resultedData = _response;
      })
      .catch((error) => {
        resultedData = 500;
      });
    return resultedData;
  }

  // Get recommended products
  async getRecommendedProducts(_skinIssue, _scoreCategory) {
    let productData = [];
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .get(
        `${BASE_URL}/api/v1/product_recommendation/?skin_issue=${_skinIssue}&score_category=${_scoreCategory}`,
        {
          headers: headers,
        }
      ).then((_response) => {
        productData = _response.data.data
      })
      .catch((error) => {
        console.log(error)
      });
    return productData;
  }
}

export class BlogsApi {
  // Get the token from sessionStorage
  token = sessionStorage.getItem("token");

  // Function to construct authorization headers if token is present
  checkIsAuthenticated() {
    let headers = {};
    if (this.token) {
      headers = {
        Authorization: `token ${this.token}`,
      };
    }
    return headers;
  }

  // Retrieve blogs using provided ID
  async getBlogs(id) {
    // Initialize variable to store data
    let getBlog = null;
    await axios
      .get(`${BASE_URL}/api/v1/get-all-blogs/${id}/`, {
        headers: this.checkIsAuthenticated(),
      })
      .then((response) => {
        getBlog = response.data;
      })
      .catch((error) => {
        // FEL 176 Added Page Not Found page
        getBlog = { errorStatus: error.response?.status };
      });
    return getBlog;
  }

  // Add likes and comments for blogs
  async addGeneralLikesAndComment(_url, _data) {
    let data = null;
    await axios
      .post(`${BASE_URL}${_url}`, _data, {
        headers: {
          Authorization: `token ${this.token}`,
        },
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        data = { errorStatus: error.response?.status };
      });
    return data;
  }

  // Retrieve blogs with optional filtering
  async blogsApi(addFilter) {
    let data = null;
    await axios
      .get(`${BASE_URL}/api/v1/get-all-blogs/${addFilter}`, {
        headers: this.checkIsAuthenticated(),
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }

  // Retrieve blog categories
  async getCategory() {
    let data = null;
    await axios
      .get(`${BASE_URL}/api/v1/blog-categories/`)
      .then((response) => {
        data = response.data.data;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }

  // Retrieve banner images
  async getBannerImage() {
    let data = null;
    await axios
      .get(`${BASE_URL}/api/v1/banner-images/`, {
        headers: this.checkIsAuthenticated(),
      })
      .then((response) => {
        data = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  }

  // Retrieve search results for blogs
  async getSearch(searchData) {
    let data = null;
    await axios
      .get(`${BASE_URL}/api/v1/get-all-blogs/?search=${searchData}`, {
        headers: this.checkIsAuthenticated(),
      })
      .then((response) => {
        data = response.data.results;
      });
    return data;
  }

  // Verify user email using OTP
  async verifyUserEmailOTP(_email, _otp) {
    let data = null;
    let genData = {
      email: _email,
      otp: _otp,
    };
    await axios
      .post(`${BASE_URL}/api/v1/user-create/verify-otp/`, genData)
      .then((_response) => {
        data = _response;
      })
      .catch((error) => {
        data = error.response;
        console.error(error);
      });
    return data;
  }

  // Generate OTP for user email authentication
  async getUserEmailAuthentication(_data) {
    let data = null;
    let genData = {
      email: _data,
    };
    await axios
      .post(`${BASE_URL}/api/v1/user-create/generate-otp/`, genData)
      .then((_response) => {
        data = _response.status;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }

  // Complete user profile creation
  async completeUserProfile(_data) {
    let data = null;
    await axios
      .post(`${BASE_URL}/api/v1/user-create/`, _data)
      .then((_response) => {
        data = _response.data;
      })
      .catch((error) => {
        data = error.response;
      });
    return data;
  }

  // Check user state using provided data
  async checkUser(_data) {
    let data = null;
    await axios
      .post(`${BASE_URL}/api/v1/user-create/user-state/`, _data)
      .then((_response) => {
        data = _response.data.data.type;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }

  // Edit a comment & reply using provided URL and data
  async editComment(_url, _data) {
    let data = null;
    await axios
      .put(`${BASE_URL}${_url}`, _data, {
        headers: {
          Authorization: `token ${this.token}`,
        },
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        data = { errorStatus: error.response?.status };
      });
    return data;
  }

  // Delete a comment using provided URL and data
  async deleteComment(_url, _data) {
    let data = null;
    await axios
      .delete(`${BASE_URL}${_url}`, {
        headers: {
          Authorization: `token ${this.token}`,
        },
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        data = { errorStatus: error.response?.status };
      });
    return data;
  }
  async blogVisitorCount(slug) {
    // UUID is based on device uuid and also it's browser specific. FEL-130
    let device_uuid = getDeviceUUID();
    let data = null;
    await axios
      .get(
        `${BASE_URL}/api/v1/blog-visitor-count/?uuid=${device_uuid}&slug=${slug}`
      )
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        data = { errorStatus: error.response?.status };
      });
    return data;
  }

  async topVisitedBlogs() {
    let data = null;
    await axios
      .get(`${BASE_URL}/api/v1/top-visitor-blogs/`, {
        headers: this.checkIsAuthenticated(),
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  }

  async reportComment(_url, _data) {
    //  FEL-128 Called Report Comment API.
    let data = null;
    await axios
      .post(`${BASE_URL}${_url}`, _data, {
        headers: {
          Authorization: `token ${this.token}`,
        },
      })
      .then((response) => {
        data = response.data;
      })
      .catch((error) => {
        data = { errorStatus: error.response?.status };
      });
    return data;
  }
}
