import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { ColorRing } from 'react-loader-spinner'
import { textFormating } from "../../../Pages/Utils/Utils";
import "./style.css"

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);


export const Skinconcern = (props) => {
    const [checkState, setCheckState] = useState(false);
    const [getAllScoresState, setAllScoresState] = useState({});
    const [getColorMapState, setColorMapState] = useState();
    const [getDataState, setDataState] = useState();
    const [getSortedDataState, setSortedDataState] = useState();
    var data;
    var pointColors = [];

    //Returns Different Colors For Display Points
    function setPointsColor() {
        Object.entries(getSortedDataState).forEach((_item) => {
            pointColors.push(_item[1]['color'])
        });
    }

    //Returns An Array Of Sequenced Lables 
    function setLables() {
        let settedLables = [];
        for (let i = 0; i < getSortedDataState.length; i++) {
            const element = Object.keys(getSortedDataState)[i];
            settedLables.push(textFormating(getSortedDataState[element]['issue']));
        }
        return settedLables
    }

    //Checks And Returns If All Issues Are Loaded
    function getScoreData() {
        var dataC = [];
        if (getSortedDataState) {
            for (let i = 0; i < getSortedDataState.length; i++) {
                const element = Object.keys(getSortedDataState)[i];
                dataC.push(getSortedDataState[element]["score"]);
            }
        }
        if (props?.getCheckCalledState === 6) {
            setCheckState(true);
        }
        else {
            setCheckState(false);
        }
        return dataC;
    }

    //Maps And Returns Sorted Data
    function mapSorting() {
        let skinScoreSequence = [];
        for (let issue of Object.keys(getAllScoresState)) {
            let score = getAllScoresState[issue];
            let dataList = { score: score, sequence: getColorMapState.filter((item) => item.skin_issue === issue)?.[0]?.sequence_id, issue, color: getColorMapState.filter((item) => item.skin_issue === issue)?.[0]?.colour }
            skinScoreSequence.push(dataList);
        }
        skinScoreSequence = skinScoreSequence.sort((a, b) => a.sequence - b.sequence);
        setSortedDataState(skinScoreSequence);
    }
    if (checkState) {
        setPointsColor();
    }

    checkState === true ? data = {
        labels: setLables(),
        datasets: [
            {
                data: getDataState,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(250,0,0,0.88)");
                    gradient.addColorStop(1, "rgba(0,0,255,0.66)");
                    return gradient;
                },
                borderColor: "0.2px solid ",
                borderWidth: 1,
                pointBackgroundColor: pointColors
            }
        ],
    } : data = {
        labels: ['Wrinkles', 'Acne', 'Pigmentation', 'Dark Circles', 'Open Pores', 'Dark Spots'],
        datasets: [
            {
                data: [0, 0, 0, 0, 0, 0],
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(250,0,0,0.66)");
                    gradient.addColorStop(1, "rgba(0,0,255,0.77)");
                    return gradient;
                },
                borderColor: "0.2px solid ",
                borderWidth: 1,
            },
            {
                data: [100, 100, 100, 100, 100, 100],
                backgroundColor: 'rgba(244, 244, 244, 0.7)',
                borderColor: 'rgba(244, 244, 244, 0.7)',
                borderWidth: 0,
                pointBackgroundColor: '#00000000'
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            r: {
                grid: {
                    display: true,
                    color: '#d8004f'
                },
                angleLines: {
                    display: true,
                    color: props.tileFontColor !== '#000000' ? props.tileFontColor : 'rgba(0, 0, 0, 0.8)',
                },
                pointLabels: {
                    display: true, // Hides the labels around the radar chart
                    font: {
                        size: 8.5,
                        weight: 'bold'
                    },
                    color: pointColors
                },
                ticks: {
                    display: false, // Hides the labels in the middel (numbers)
                },
                max: 100,
                min: 1,
                beginAtZero: false
            },
        },
        responsive: true,
        maintainAspectRatio: true,
    }

    useEffect(() => {
        setDataState(getScoreData);
        // eslint-disable-next-line
    }, [getSortedDataState])

    useEffect(() => {
        setAllScoresState(props.score);
        setColorMapState(props.allIssues);
        mapSorting();
        // eslint-disable-next-line
    }, [props])

    return (
        <>
            <div>
                {props?.getCheckCalledState === 6 ?
                    <div className="SK_Main mt-3" style={{ background: props.tileBackground, color: props.tileFontColor }}>
                        <h5 className='d-flex justify-content-center'>Your<span className='skText'>&nbsp;Skin Concerns</span></h5>
                        <div className="container d-flex justify-content-center SK_Concren_Chart ">
                            <Radar data={data} options={options} className='radarChartCanvas' />
                        </div>
                    </div>
                    :
                    <div className="SK_Main mt-3" style={{ background: props.tileBackground, color: props.tileFontColor }}>
                        <h5 className='d-flex justify-content-center'>Your<span className='skText'>&nbsp;Skin Concerns</span></h5>
                        <div className="container d-flex justify-content-center SK_Concren_Chart ">
                            <ColorRing
                                visible={true}
                                height="255"
                                width="85"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#ff0000', '#0000ff', '#ff0000', '#0000ff', '#ff0000']}
                            />
                        </div>
                    </div>
                }
            </div>
        </ >
    )
}