import React, { useEffect } from "react";
import Header from "../../Components/BlogsComponents/Header";
import Footer from "../../Components/BlogsComponents/Footer";
import Mode from "../../Components/BlogsComponents/Mode";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function BlogsLayout(props) {
  const Inner = props.inner;
  const [refreshInner, setRefreshInner] = useState(false);
  const { pathname } = useLocation();

  const handleHeaderClick = () => {
    // When the header is clicked, toggle the refreshInner state
    setRefreshInner(!refreshInner);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, [pathname]);

  return (
    <>
      <Mode />
      <Header refershHeader={handleHeaderClick} />
      <div className="main-content">
        <div className="container">
          <Inner refresh={refreshInner}></Inner>
        </div>
      </div>
      <Footer />
    </>
  );
}
