import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { auth } from "../Config/firebaseConfig";


//Invisible Captcha Verification
function onCapthaVerify(_btn) {
    try {
        auth.appVerificationDisabledForTesting = false
        window.recaptchaVerifier = new RecaptchaVerifier(auth, _btn.current, {
            'size': 'invisible',
            'callback': (response) => {
                onSignUp();
            },
            'expired-callback': () => {
            }
        });
        window.recaptchaVerifier.render();
    } catch (error) {
        console.log("Internal Server Error")
    }

}

//Signup With Firebase
async function onSignUp(_number, _btnRef) {
    try {
        onCapthaVerify(_btnRef);
        const phoneNumber = _number;
        const appVerifier = window.recaptchaVerifier;
        //FEL-167 Exception Handling
        let returnValue = signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((_confirmationResult) => {
            window.confirmationResult = _confirmationResult;
            return true;
        }).catch(e => {
            if (e instanceof FirebaseError) {
                return false;
            }
        });
        return returnValue;
    } catch (error) {
        console.log("Internal Server Errror")
    }

}


export { onCapthaVerify, onSignUp }