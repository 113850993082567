import React, { useState, useEffect } from 'react'
import { useLocation, useNavigationType, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import { Skinconcern } from '../../Components/AnalyzerComponents/Skinconcern';
import { SkinAge } from '../../Components/AnalyzerComponents/SkinAge';
import { SkinAnalysis } from '../../Components/AnalyzerComponents/SkinAnalysis';
import { Overview } from '../../Components/AnalyzerComponents/Overview';
import Result from '../../Components/AnalyzerComponents/Result'
import UserInfo from '../UserDetails/UserInfo';
import SolutionsContext from '../../Context/SolutionContext';
import { AnalyzerApi } from '../../Network/API';
import Header from '../../Components/AnalyzerComponents/Header';
import CustomToast from '../../Components/AnalyzerComponents/CustomToast';
import '../../css/Layout.css';

export default function Layout() {
  const [getModeState, setModeState] = useState({
    fontColor: '#000000',
    backgroundColor: '#ECEEF5',
    tileBG: '#FFFFFF',
    tileFont: '#000000'
  });

  const [getResultsState, setResultsState] = useState({});
  const [getTotalScoreState, setTotalScoreState] = useState({});
  const [getMaskDataState, setMaskDataState] = useState({});
  const [getInformationDataState, setInformationDataState] = useState({});
  const [getMaskCanvasState, setMaskCanvasState] = useState(null);
  const [, setReRenderState] = useState(null);
  const [getErrorFlagState, setErrorFlagState] = useState(false);
  const [getErrorMessagesState, setErrorMessagesState] = useState([]);
  const [getUserViewImageState, setUserViewImageState] = useState([false, -1]); //FEL-104 To Handle Toggle Actions
  const [getUserDetailsFilledState, setUserDetailsFilledState] = useState(false);
  const [getCheckCalledState, setCheckCalledState] = useState(0);
  let storeErrorMessage = [];
  let tempCalled = 0;
  var checkMode = sessionStorage.getItem('eventMode');

  const imagePrefix = "data:image:png;base64,";

  const navType = useNavigationType();
  const navigate = useNavigate();

  useEffect(() => {
    if (navType === 'POP') {
      navigate('/SkinAnalyzer');
      window.location.reload();
    }
  })


  let { state } = useLocation();
  if (state) {
    state = JSON.parse(state);
  }
  async function loadData() {
    const issueData = new AnalyzerApi();
    if (state?.getSkinIssueState.length === undefined) {
      getErrorMessagesState(['Something went wrong']);
      navigate('/SkinAnalyzer');
    }
    else {
      for (let i = 0; i < state?.getSkinIssueState.length; i++) {
        const element = state?.getSkinIssueState[i];
        issueData.getIssueData(state?._base64, element["skin_issue"]).then((_response) => {
          if (_response.status === 200) {
            setReRenderState(_response.data.analyzed_data);
            let skinIssueData = getResultsState;
            let skinScore = getTotalScoreState;
            let maskImages = getMaskDataState;
            let infoData = getInformationDataState;
            let makeImage = new Image();
            makeImage.src = "data:image/png;base64," + _response.data.analyzed_data.mask;
            makeImage.name = element["skin_issue"];
            maskImages[element["skin_issue"]] = makeImage;
            skinIssueData[element["skin_issue"]] = _response.data.analyzed_data;
            skinScore[element["skin_issue"]] = _response.data.analyzed_data.score;
            infoData[element["skin_issue"]] = _response.data.analyzed_data.information;
            setInformationDataState(infoData);
            setMaskDataState(maskImages);
            setTotalScoreState(skinScore);
            setResultsState(skinIssueData);
          }
          else if (_response.status === 400) {
            storeErrorMessage.push(_response.data.message);
            setErrorMessagesState(storeErrorMessage);
            setErrorFlagState(true);
          }
          // eslint-disable-next-line
        }).then(() => {
          tempCalled += 1;
          setCheckCalledState(tempCalled);
        });
      }
    }
  }

  useEffect(() => {
    if (checkMode === '') {
      setUserDetailsFilledState(true);
      if (Object.keys(getResultsState).length === 0) {
        loadData();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (checkMode === 'event' && getUserDetailsFilledState) {
      if (Object.keys(getResultsState).length === 0) {
        loadData();
      }
    }
    // eslint-disable-next-line
  }, [getUserDetailsFilledState]);
  return (
    <>
      {/* FEL-273 Removed Text As Suggested  */}
      <Header setTheme={setModeState} />
      {checkMode === 'event' && !getUserDetailsFilledState ?
        <>
          <UserInfo
            setUserDetailsFilledState={setUserDetailsFilledState}
            bodyBackground={getModeState.backgroundColor}
            color={getModeState.fontColor}
            tileBackground={getModeState.tileBG}
            tileFontColor={getModeState.tileFont}
          />
        </>
        :
        ''
      }

      {getErrorFlagState && getErrorMessagesState.map((_value, _key) => {
        return (
          <CustomToast message={_value} getErrorFlagState={getErrorFlagState} key={_key} />
        )
      })}
      {getUserDetailsFilledState &&

        <Container className='mt-3'>
          <Row>
            <Col md={5}>
              <Result base64={imagePrefix + state?._base64} maskCanvas={setMaskCanvasState} />
            </Col>
            <Col md={7}>
              <Row>
                <div className="divWrapper">
                  <SolutionsContext>
                    <SkinAnalysis
                      setUserViewImageState={getUserViewImageState}
                      analyzedResults={getResultsState}
                      allIssues={state?.getSkinIssueState}
                      maskImages={getMaskDataState}
                      maskCanvas={getMaskCanvasState}
                      scoreCategory={state?.getScoreCatrgoryState}
                      bodyBackground={getModeState.backgroundColor}
                      tileBackground={getModeState.tileBG}
                      tileFontColor={getModeState.tileFont}
                      color={getModeState.fontColor} >
                    </SkinAnalysis>
                  </SolutionsContext>
                </div>
              </Row>
              <Row>
                <Col md={6} >
                  <Overview
                    setUserViewImageState={setUserViewImageState}
                    score={getTotalScoreState}
                    scoreCategory={state?.getScoreCatrgoryState}
                    allIssues={state?.getSkinIssueState}
                    maskImages={getMaskDataState}
                    maskCanvas={getMaskCanvasState}
                    tileBackground={getModeState.tileBG} tileFontColor={getModeState.tileFont} >
                  </Overview>
                  <SkinAge base64={state?._base64} tileBackground={getModeState.tileBG} tileFontColor={getModeState.tileFont} />
                </Col>
                <Col md={6}>
                  <Skinconcern
                    score={getTotalScoreState}
                    allIssues={state?.getSkinIssueState}
                    getCheckCalledState={getCheckCalledState}
                    tileBackground={getModeState.tileBG} tileFontColor={getModeState.tileFont} >
                  </Skinconcern>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container >
      }
    </>
  )
}
