import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GiBookmarklet } from 'react-icons/gi';
import { MdFaceRetouchingNatural } from 'react-icons/md';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/LandingPage.css'

export default function LandingPage() {
    const navigate = useNavigate();
    function handleAnalyzerClick() {
        navigate('/SkinAnalyzer');
    }
    function handleBlogsClick() {
        navigate('/Blogs');
    }
    return (
        <>
            <Container className='landingContainer '>
                <div className="mainCont"></div>
                <Row className='d-flex align-items-center landingContainer'>
                    <Col md={6} className='d-flex justify-content-center'>
                        <div>
                            <div className="compHolder d-flex justify-content-center align-items-center" onClick={handleAnalyzerClick}>
                                <MdFaceRetouchingNatural size={50} />
                            </div>
                            <span className='d-flex justify-content-center align-items-center mainTexts'>SkinAnalyzer</span>
                        </div>
                    </Col>
                    <Col md={6} className='d-flex justify-content-center'>
                        <div>
                            <div className="compHolder d-flex justify-content-center align-items-center" onClick={handleBlogsClick}>
                                <GiBookmarklet size={50} />
                            </div>
                            <span className='d-flex justify-content-center align-items-center mainTexts'>Blogs</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
