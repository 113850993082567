import React, { useEffect, useState } from "react";
import { BlogsApi } from "../../../Network/API";
import { BASE_URL } from "../../../Pages/Config/Config";
import { getFormattedTime } from "../../../Pages/Utils/Utils";
import SignInToast, { ReportCommentToast } from "../SignInToast";
import { FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import profile from "../../../Images/profile.png";
import Button from "react-bootstrap/Button";

export default function Reply(props) {
  const [replyComments, setReplyComments] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const blogsApi = new BlogsApi();
  const [isLike, setIsLike] = useState(true);
  const [edit, setEdit] = useState(false);
  const [commentEdit, setCommentEdit] = useState("");
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [maxEditLimit, setMaxEditLimit] = useState(200);
  const [editHidden, setEditHidden] = useState(false);
  const [reportCommentModel, setReportCommentModel] = useState(false);
  const [commentId, setCommentId] = useState();

  useEffect(() => {
    setReplyComments(props.replies);
  }, [props.replies]);

  const replyLike = (id) => {
    setIsLike(!isLike);
    const data = {
      comment_reply_id: id,
    };
    blogsApi
      .addGeneralLikesAndComment("/api/v1/reply-comment-likes/", data)
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
  };

  //  FEL-153 Report Reply Comment API.
  const reportComment = (id) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setReportCommentModel(true);
    setCommentId(id);
  };

  const editReplyComments = (id) => {
    blogsApi
      .editComment(`/api/v1/replies-comment/${id}/`, {
        reply: commentEdit,
      })
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
    setEditHidden(!editHidden);
  };

  const editComment = (id, comment) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setEditHidden(!editHidden);
    setEdit(id);
    setCommentEdit(comment);
  };

  const handleClose = () => {
    setShow(false);

    blogsApi
      .deleteComment(`/api/v1/replies-comment/${deleteId}/`)
      .then((_response) => {
        if (_response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        props.getApiData();
      });
  };

  const deleteReplyComment = (id) => {
    const token = sessionStorage.getItem("token");
    if (token === null || token.length < 5) {
      setShowErrorModal(true);
      return;
    }
    setDeleteId(id);
    setShow(true);
  };
  const handleEditMaxLimit = (value) => {
    setCommentEdit(value);
    setMaxEditLimit(200 - value.length);
  };

  return (
    <>
      {showErrorModal && (
        <SignInToast
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
        />
      )}
      {/* FEL-153 Report Reply Comment */}
      {reportCommentModel && (
        <ReportCommentToast
          show={reportCommentModel}
          onHide={() => setReportCommentModel(false)}
          comment={commentId}
          setReportComment={setReportCommentModel}
          isReportReplyComment={true}
        />
      )}
      <div className="reply-container row mx-5 mt-3">
        {replyComments &&
          replyComments.map((reply, key) => {
            return (
              <div className="row" key={reply.reply_id}>
                <div className="">
                  <div>
                    <div className="d-flex">
                      <div>
                        {reply.author.photo && (
                          <img
                            className="profileImage mr-0"
                            src={BASE_URL + reply.author.photo}
                          />
                        )}
                        {!reply.author.photo && (
                          <img className="profileImage mr-0" src={profile} />
                        )}
                      </div>
                      <div className="d-flex">
                        <span
                          className="my-auto"
                          style={{ color: props.theme.getModeState.color }}
                        >
                          <b>
                            {reply.author.first_name} {reply.author.last_name}
                          </b>
                        </span>
                        <span className="mx-3 text-secondary my-auto align-items-center">
                          {getFormattedTime(reply.replied_at)}{" "}
                          <span className="badge rounded-pill bg-secondary">
                            {reply.edited && "Edited"}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="mx-5 ml-0 mb-3">
                      <span
                        style={{
                          color: props.theme.getModeState.color,
                          wordWrap: "break-word",
                        }}
                      >
                        {reply.reply}
                      </span>
                    </div>
                    <div className="mx-5 ml-0">
                      <a onClick={() => replyLike(reply.reply_id)}>
                        {!reply.user_liked ? (
                          <FaRegThumbsUp
                            fill={props.theme.getModeState.color}
                            className="custom-icon"
                            role="button"
                          />
                        ) : (
                          <FaThumbsUp
                            fill="#ff0000"
                            className="custom-icon"
                            role="button"
                          />
                        )}
                      </a>
                      <span
                        className="mr-2"
                        style={{
                          color: props.theme.getModeState.color,
                        }}
                      >
                        {"  "}
                        {reply.reply_total_likes}
                      </span>

                      {reply.is_commenter == true && (
                        <>
                          <span
                            className="mx-1 fa-icon"
                            role="button"
                            onClick={() =>
                              editComment(reply.reply_id, reply.reply)
                            }
                            style={{
                              color: props.theme.getModeState.color,
                            }}
                          >
                            Edit
                          </span>
                          <span
                            className="mx-1 fa-icon"
                            role="button"
                            onClick={() => deleteReplyComment(reply.reply_id)}
                            style={{
                              color: props.theme.getModeState.color,
                            }}
                          >
                            Delete
                          </span>
                        </>
                      )}
                      <span
                        className="mx-1 fa-icon"
                        role="button"
                        onClick={() => reportComment(reply.reply_id)}
                        style={{
                          color: props.theme.getModeState.color,
                        }}
                      >
                        Report
                      </span>

                      <div className="reply-div mx-5 ml-0">
                        {edit === reply.reply_id && editHidden && (
                          <div className="mb-2">
                            <p
                              className="m-2 float-end"
                              style={{
                                color: props.theme.getModeState.color,
                              }}
                            >
                              {" "}
                              {maxEditLimit} / 200
                            </p>
                            <textarea
                              className="form-control mb-2"
                              rows="3"
                              value={commentEdit}
                              maxLength={200}
                              onChange={(event) =>
                                handleEditMaxLimit(event.target.value)
                              }
                              placeholder="Hi! Write your comment here.."
                              style={{
                                backgroundColor:
                                  props.theme.getModeState.tileBG,
                                color: props.theme.getModeState.color,
                              }}
                            />
                            <button
                              className="border send"
                              onClick={() => editReplyComments(reply.reply_id)}
                            >
                              Send
                            </button>
                          </div>
                        )}

                        <Modal
                          show={show}
                          onHide={() => setShow(false)}
                          centered
                        >
                          <Modal.Header
                            className="d-flex justify-content-center"
                            style={{
                              backgroundColor: props.theme.getModeState.tileBG,
                              color: props.theme.getModeState.color,
                            }}
                          >
                            <Modal.Title>
                              Do you want to delete this reply?
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Footer
                            className="d-flex justify-content-center"
                            style={{
                              backgroundColor: props.theme.getModeState.tileBG,
                              color: props.theme.getModeState.color,
                            }}
                          >
                            <Button
                              variant="primary"
                              onClick={() => handleClose(reply.reply_id)}
                            >
                              Confirm
                            </Button>
                            <Button
                              variant="secondary"
                              onClick={() => setShow(false)}
                            >
                              Cancel
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
