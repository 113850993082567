import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LaunchPage from "./Pages/AnalyzerPages/LaunchPage";
import Layout from "./Pages/Layouts/Layout";
import WebcamLayout from "./Pages/Layouts/WebcamLayout";
import BlogContent from "./Pages/BlogsPages/BlogContent";
import BlogsList from "./Pages/BlogsPages/BlogsList";
import BlogsDashboard from "./Pages/BlogsPages/BlogDashboard";
import UserAuth from "./Pages/UserDetails/UserAuth";
import OtpAuth from "./Pages/UserDetails/OtpAuth";
import Profile from "./Pages/UserDetails/Profile";
import LandingPage from "./Pages/AnalyzerPages/LandingPage";
import BlogPageNotFound from "./Pages/BlogsPages/BlogPageNotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/SkinAnalyzer.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "*",
      element: <BlogPageNotFound />,
    },
    {
      path: "/SkinAnalyzer",
      children: [
        {
          index: true,
          element: <LaunchPage />,
        },
        {
          path: "Layout",
          element: <Layout />,
        },
        {
          path: "Webcam",
          element: <WebcamLayout />,
        }
      ],
    },
    {
      path: "/Blogs",
      children: [
        {
          index: true,
          element: <BlogsDashboard />,
        },
        {
          path: "User",
          element: <UserAuth />,
        },

        {
          path: "EnterOtp",
          element: <OtpAuth />,
        },
        {
          path: "CompleteProfile",
          element: <Profile />,
        },
        {
          path: "Post-content/:id",
          element: <BlogContent />,
        },
        {
          path: "List",
          element: <BlogsList />,
        },
      ],
    }
  ]);
  return <RouterProvider router={router} />;
}

export default App;
