import React, { useEffect } from "react";
import BlogsLayout from "../Layouts/BlogsLayout";
import PageNotFound from "../../Components/BlogsComponents/PageNotFound";

export default function BlogPageNotFound() {
  // FEL-176 PageNotFound Page.
  return <>
      <div>
        <BlogsLayout inner={PageNotFound}  />
      </div>
    </>
}