import React from 'react'
import './style.css'

export const Tips = (props) => {
    return (
        <>
            <span className=''>
                <b> Tips to Reduce {props.featureName} and Restore Radiant Skin</b>
            </span>
            <span className='scrollbar' id='style-14'>
                <ol className='tipsListText mt-2'>
                    {
                        //FEL-247 Updated due to new enpoint 
                        props.data === undefined ? <span>No Data Avaibalbe!</span> :
                            props.data?.length > 0 ? props.data[0]['content']?.map((_value, _index) => {
                                return (
                                    <li key={_index} className='tipsData'>{_value}</li>
                                )
                            }) :
                                <>
                                    <span>No Data Avaibalbe!</span>
                                </>

                    }
                </ol>

            </span>
        </>
    )
}