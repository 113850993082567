import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { isMobile } from 'react-device-detect';
import { BsBoxArrowInDownLeft } from 'react-icons/bs';
import { Tips } from '../Tips/';
import { Remedies } from '../Remedies';
import { AnalyzerApi } from '../../../Network/API';
import CustomToast from '../../AnalyzerComponents/CustomToast';
import mainContext from '../../../Context/indexContext';
import { textFormating } from '../../../Pages/Utils/Utils';
import "./style.css"
export const Solutions = (props) => {
    const [getCompState, setCompState] = useState('Tips');
    const [getTipsActiveState, setTipsActiveState] = useState('activeClass');
    const [getRemActiveState, setRemActiveState] = useState('');
    const [getErrorFlagState, setErrorFlagState] = useState(false);
    const [getTipsData, setTipsData] = useState();
    const [getRemediesData, setRemediesData] = useState();
    const [getFeatureDescriptionState, setFeatureDescriptionState] = useState([]);
    const getModalContext = useContext(mainContext);

    let tipRemData = new AnalyzerApi();


    //Returns Score Details According to Scores
    function getScoreDetails(_scoreValue) {
        let scoreCategory = null;
        for (let i = 0; i < props?.scoreCategory.length; i++) {
            const element = props?.scoreCategory[i];
            if (_scoreValue >= element['min_val'] && _scoreValue <= element['max_val']) {
                scoreCategory = element;
            }
        }
        return scoreCategory;
    }
    //Closes The Modal
    function handleBackClick() {
        getModalContext.setShowState(false)
    }

    //Handles The Data Tabs For Tips And Remedies
    async function handleNavTabToggle(_Toggler) {
        setCompState(_Toggler);
        if (_Toggler === "Remedies") {
            tipRemData.getTipsRemediesData(_Toggler.toLowerCase(), props.data[0].toUpperCase(), getScoreDetails(props.data[1][0])['category_name'], 'General').then((_response) => {
                if (_response !== 500) {
                    setRemediesData(_response);
                }
                else {
                    setErrorFlagState(true);
                }
            });
            setRemActiveState('activeClass')
            setTipsActiveState('');
        }
        else if (_Toggler === "Tips") {
            tipRemData.getTipsRemediesData(_Toggler.toLowerCase(), props.data[0].toUpperCase(), getScoreDetails(props.data[1][0])['category_name'], 'General').then((_response) => {
                if (_response !== 500) {
                    //FEL-247 Updated due to new enpoint 
                    setTipsData(_response[0]);
                }
                else {
                    setErrorFlagState(true);
                }
            });
            setTipsActiveState('activeClass');
            setRemActiveState('');
        }

    }
    useEffect(() => {
        for (let i = 0; i < props.featureNameDesc.length; i++) {
            // FEL-273 Definitions Display
            const element = props.featureNameDesc[i];
            if (props.data[0] === textFormating(element['skin_issue'])) {
                setFeatureDescriptionState(element['description'])
            }
        }
        // eslint-disable-next-line
    }, [props.featureNameDesc])



    return (
        <>
            <Container className='tipRemBody' style={{ backgroundColor: props.tileBackground, color: props.tileFontColor }}>
                {/* FEL-273 For FIx For Single Time Display Of Error Message & Updated Error Message */}
                {
                    getErrorFlagState ?
                        <CustomToast message="Tips & Remedies are currently unavailable! " getErrorFlagState={getErrorFlagState} setErrorFlagState={setErrorFlagState} />
                        :
                        ""
                }
                <Row className='d-flex align-items-center mt-2 p-1'>
                    <Col md={2} xs={4} className='d-flex justify-content-center '>
                        <div className={`cpTips ${props.tileBackground === '#1E2029' ? 'darkProgresscpTips' : ""}`} style={{ background: `conic-gradient(${props.data[1][2]} ${props.data[1][0] * 3.6}deg, #ededed 0deg)` }} >
                            <div className={`cp2Tips `} style={{ background: `conic-gradient(#00000000 0deg, ${props.data[1][2]} 0deg)` }}>
                                <div className={`cp3Tips`} style={{ background: `conic-gradient(#00000000 0deg, ${props.tileBackground} 0deg` }}>
                                    <span className="pvTips" >
                                        <b>
                                            {props.data[1][0]}
                                        </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={9} xs={6}>
                        <Row className='p-1'>
                            <span className='p-0'>
                                <b>{props.data[0]}</b>
                            </span>
                        </Row>
                        <Row>
                            <span className='featureNameDescription p-0'>{getFeatureDescriptionState}</span>
                        </Row>
                    </Col>
                    {
                        isMobile ?
                            <div className={`${isMobile ? 'd-flex justify-content-center col-2' : 'col-md-1'}`}>
                                <div className="d-flex justify-content-center align-items-center remBack " onClick={handleBackClick}>
                                    <BsBoxArrowInDownLeft fill='#ffffff' />
                                </div>
                            </div> :
                            ''
                    }
                </Row>
                <Row className='p-1'>
                    <Col md={6} xs={12} className={`d-flex mb-3 justify-content-${isMobile ? 'center' : 'start'} align-items-center  mt-2`}>
                        <nav className="nav tipRemNav nav-pills p-1">
                            <button className={`d-flex justify-content-center nav-link ${getTipsActiveState}`} onClick={() => handleNavTabToggle("Tips")}>Tips</button>
                            <button className={`d-flex justify-content-center nav-link ${getRemActiveState}`} onClick={() => handleNavTabToggle("Remedies")}>Remedies</button>
                        </nav>
                    </Col>
                    {/* <Col md={6} className={`d-flex mb-3 justify-content-${isMobile ? 'center' : 'start'} align-items-center  mt-2`}>
                        <ul className="nav nav-underline generalExpertTab">
                            <li className="nav-item genExpert">
                                <button className="d-flex justify-content-center nav-link active" aria-current="page" style={{ color: props.color }} >General</button>
                            </li>
                            <li className="nav-item genExpert">
                                <button className="d-flex justify-content-center nav-link" aria-disabled="true" style={{ color: props.color }}>Expert</button>
                            </li>
                        </ul>
                    </Col> */}
                    {getCompState === "Tips" ? <Tips data={props.tipsData ? props.tipsData : getTipsData} featureName={props.data[0]} /> : getCompState === "Remedies" ? <Remedies data={getRemediesData} featureName={props.data[0]} /> : 'Data Not Found'}
                </Row>
                <Row className='p-1'>
                    <div className="tipRemNote line-height-normal p-2" style={{ color: props.tileFontColor === "#000000" ? props.tileFontColor : '#000000' }}>
                        <span className='tipRemNoteDesc'>
                            Note: Please note that severe skin problems may require professional advice and personalized treatment plans. It's essential to consult with a dermatologist or skincare professional to determine the most suitable approach for your specific condition.
                        </span>
                    </div>
                </Row>
            </Container>
        </>
    )
}
