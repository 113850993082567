import React, { useContext, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";

import { BlogsApi } from "../../../Network/API";
import likeButton from "../../../Images/likebutton.svg";
import unfillLikeButton from "../../../Images/unfillheart.svg";
import { isMobile } from "react-device-detect";
import SignInToast from "../SignInToast";
import { Carousel, Card } from "react-bootstrap";
import "./style.css";
import { useNavigate } from "react-router-dom";
import mainContext from "../../../Context/indexContext";
import {
  timestrampToDate,
  esstimatedTimeToRead,
} from "../../../Pages/Utils/Utils";
import MostLiked from "../MostLiked";
export default function Featured(props) {
  const blogApi = new BlogsApi();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [bannerImage, setBannerImage] = useState();
  const themeContext = useContext(mainContext);
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const navigateAnoterPage = (slug) => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      navigate(`/Blogs/Post-content/${slug}`);
    }, 100); // Increased delay to 100 milliseconds
  };

  const likedBlogs = (id) => {
    blogApi
      .addGeneralLikesAndComment("/api/v1/blog-likes/", {
        blog_id: id,
      })
      .then((response) => {
        if (response.errorStatus === 401) {
          setShowErrorModal(true);
          return;
        }
        blogApi.getBannerImage().then((response) => {
          setBannerImage(response);
        });
      });
  };

  useEffect(() => {
    blogApi.getBannerImage().then((response) => {
      setBannerImage(response);
    });
  }, [props.refresh]);

  return (
    <>
      {showErrorModal && (
        <SignInToast
          show={showErrorModal}
          onHide={() => setShowErrorModal(false)}
        />
      )}
      <div
        className="row justify-content-center align-items-center pt-4 custom-carousel"
        style={{
          color: themeContext.getModeState.color,
        }}
      >
        <div className="col-12 mb-3">
          <Card
            className="shadow"
            style={{ backgroundColor: themeContext.getModeState.tileBG }}
          >
            <Carousel
              activeIndex={activeIndex}
              onSelect={handleSelect}
              id="bannerCarousel"
            >
              {bannerImage?.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <Card.Body>
                      <img
                        className="d-block w-100"
                        src={item.banner_images}
                        alt="Image 1"
                        onClick={() => navigateAnoterPage(item.slug)}
                        style={{ cursor: "pointer" }}
                        width="auto"
                        height={isMobile ? "auto" : 400}
                      />
                      <span className="badge custom-badge text-capitalize banner-badge">
                        {item.blog_category.blog_category_name}
                      </span>
                      <a className="banner-like-button">
                        {item.user_liked && (
                          <img
                            src={likeButton}
                            className="p-1"
                            onClick={() => likedBlogs(item?.blog_id)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {!item.user_liked && (
                          <img
                            src={unfillLikeButton}
                            className="p-1"
                            onClick={() => likedBlogs(item?.blog_id)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </a>
                      <Card.Title
                        className="mt-3 card-title text-capitalize blog-titles"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateAnoterPage(item.slug)}
                      >
                        {item.blog_title}
                      </Card.Title>
                      <p
                        className="card-text blog-p"
                        style={{ color: props?.color }}
                      >
                        {timestrampToDate(item.created_at)} {"  "} &bull; {"  "}{" "}
                        {esstimatedTimeToRead(item.content)} min to read{" "}
                        <FaEye role="button" className="mx-1" />
                        <span>{item.visited_count}</span>
                      </p>
                    </Card.Body>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Card>
        </div>
      </div>

      <MostLiked
        bgColor={themeContext.getModeState.backgroundColor}
        color={themeContext.getModeState.color}
        tileBG={themeContext.getModeState.tileBG}
        refersh = {props.refresh}
      />
    </>
  );
}
