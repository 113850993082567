// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, OAuthProvider, getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVm6ATzpr5qHxjihPQyHsZgMDKWRCkJx0",
  authDomain: "project-selfstylo.firebaseapp.com",
  projectId: "project-selfstylo",
  storageBucket: "project-selfstylo.appspot.com",
  messagingSenderId: "547371970732",
  appId: "1:547371970732:web:9a380cb1783c5d18126f09",
  measurementId: "G-W6VH8CDXQD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const apProvider = new OAuthProvider();
export { app, auth, provider, apProvider };
