import React, { useContext, useState } from "react";
import { FaSearch, FaPlus } from "react-icons/fa";
import "./style.css";
import bannerMiddle from "../../../Images/banner-middle.png";
import Logo from "../../../Images/felicita.svg";
import { useLocation, useNavigate } from "react-router-dom";
import profile from "../../../Images/profile.png";
import { BlogsApi } from "../../../Network/API";
import mainContext from "../../../Context/indexContext";
import { isMobile } from "react-device-detect";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Header(props) {
  // State variables
  const [input, setInput] = useState("");
  const [search, setsearchData] = useState();
  const location = useLocation();
  const blogApi = new BlogsApi();
  const currentPage = location.pathname.toLowerCase();
  let checkedPageIsBlog = currentPage === "/blogs" || currentPage === "/blogs/"
  const navigate = useNavigate();
  const themeContext = useContext(mainContext); // Access the context for theme
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [show, setShow] = useState(false);
  let userImage = sessionStorage.getItem("userImage");

  // Handle input change for search
  const handleChange = (value) => {
    setInput(value);
    // Call the API to get search results
    blogApi.getSearch(value).then((_response) => {
      setsearchData(_response);
    });
  };

  // Navigate to post content and clear input
  const naviPostContent = (slug) => {
    setInput("");
    navigate(`/Blogs/Post-content/${slug}`);
  };

  // Handle closing the modal and clearing token
  const handleClose = () => {
    setToken(sessionStorage.removeItem("token"));
    setToken(sessionStorage.removeItem("userImage"));
    props.refershHeader();
    setShow(false);
  };
  // Handle showing the modal
  const handleShow = () => setShow(true);

  //navigate to another page. FEL - 122
  const navigateAnotherPage = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    navigate("/Blogs");
  };
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className={`col-md-3 ${isMobile ? "d-flex justify-content-center" : ""}`}>
              <div className={`${isMobile ? '' : 'd-flex justify-content-center'}`}>
                <img src={Logo} className="logo" onClick={() => navigateAnotherPage()} style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 pt-5">
              <div className="test-box has-search">
                <span className="form-control-feedback">
                  <FaSearch />
                </span>
                <input
                  type="text"
                  value={input}
                  onChange={(e) => handleChange(e.target.value)}
                  className="form-control"
                  placeholder="Search"
                  style={{
                    backgroundColor: themeContext.getModeState.backgroundColor,
                    color: themeContext.getModeState.color,
                  }}
                />
                <div
                  className="results-list"
                  style={{ width: isMobile ? "auto" : "33%" }}
                >
                  {input?.length > 2 &&
                    (search?.length > 0 ? (
                      search.map((item, index) => (
                        <div
                          style={{
                            backgroundColor:
                              themeContext.getModeState.backgroundColor,
                            cursor: "pointer",
                          }}
                          key={index}
                        >
                          <div
                            className="search-result text-capitalize"
                            onClick={() => naviPostContent(item.slug)}
                            key={index}
                            style={{
                              backgroundColor:
                                themeContext.getModeState.backgroundColor,
                            }}
                          >
                            {item.title}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        className="search-result text-capitalize"
                        style={{
                          backgroundColor:
                            themeContext.getModeState.backgroundColor,
                        }}
                      >
                        No results found.
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 pt-5">
              <div className="d-flex justify-content-center align-items-center">
                {!token && (
                  <button
                    className="btn btn-dark signbutton"
                    onClick={() => navigate("/Blogs/User")}
                  >
                    Sign In
                  </button>
                )}
                {token && token.length > 10 && (
                  <button
                    className="btn btn-dark signbutton mx-1"
                    onClick={() => handleShow()}
                  >
                    Sign Out
                  </button>
                )}
                {token && (
                  <img
                    src={userImage === "null" ? profile : userImage}
                    className="login-profile mx-1"
                    alt="image not show"
                  />
                )}
                <Modal show={show} onHide={() => setShow(false)} centered>
                  <Modal.Header className="d-flex justify-content-center">
                    <Modal.Title>Sign Out</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" onClick={handleClose}>
                      Confirm
                    </Button>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>

      {checkedPageIsBlog && (
        <div className="blogs_header">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              {!isMobile ? (
                <>
                  <div className="col-12 col-sm-12 col-md-8 ps-5">
                    <div className="row ml-2">
                      <h5 className="blog-title">Blogs</h5>
                    </div>
                    <div
                      className="row header-first-content mt-3"
                      style={{ color: "#ffffff" }}
                    >
                      Unleash Your Creativity
                    </div>
                    <div
                      className="row header-first-content h6"
                      style={{ color: "#ffffff", fontWeight: "normal" }}
                    >
                      Explore and Publish with Our Blogging Platform
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <img src={bannerMiddle} className="w-75 h-75" />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
