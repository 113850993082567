import { mat4 } from 'gl-matrix';

function getSkinIssueProgramData(gl) {
    // If we don't have a GL context, give up now

    if (!gl) {
        alert(
            "Unable to initialize WebGL. Your browser or machine may not support it."
        );
        return;
    }

    // Vertex shader program

    const vsSource = `
    attribute vec3 aVertexPosition;
    attribute vec3 aColor;
    attribute vec2 aTexCoords;

    uniform mat4 uModelViewMatrix;
    uniform mat4 uProjectionMatrix;

    varying vec3 vColor;

    
    
    varying vec2 vTexCoords;

    void main() {
        gl_Position = uProjectionMatrix * uModelViewMatrix * vec4(aVertexPosition.xyz,1.0);
        
        vColor = aColor;
        vTexCoords = aTexCoords;
    }
  `;

    // Fragment shader program

    const fsSource = `
    precision lowp float;
    uniform sampler2D uSampler;
    uniform  float uOpacity;
    uniform vec3 appliedColor;
    varying vec3 vColor;
    varying vec2 vTexCoords;
    vec4 color = vec4(1.0,1.0,1.0,uOpacity);
    void main() {
      vec4 texColor = texture2D(uSampler,vTexCoords);
    
      vec3 newColor = texColor.rgb + appliedColor;
    
      gl_FragColor = vec4(newColor.rgb,texColor.a) * color ;
    
    }
  `;



    // Initialize a shader program; this is where all the lighting
    // for the vertices and so forth is established.
    const shaderProgramMakeup = initShaderProgram(gl, vsSource, fsSource);
    const programInfoMakeup = {
        program: shaderProgramMakeup,
        attribLocations: {
            vertexPosition: gl.getAttribLocation(
                shaderProgramMakeup,
                "aVertexPosition"
            ),

            colorPosition: gl.getAttribLocation(shaderProgramMakeup, "aColor"),
            texCoords: gl.getAttribLocation(shaderProgramMakeup, "aTexCoords"),
        },
        uniformLocations: {
            appliedColorPositon: gl.getUniformLocation(
                shaderProgramMakeup,
                "appliedColor"
            ),
            projectionMatrix: gl.getUniformLocation(
                shaderProgramMakeup,
                "uProjectionMatrix"
            ),
            modelViewMatrix: gl.getUniformLocation(
                shaderProgramMakeup,
                "uModelViewMatrix"
            ),
            sampler: gl.getUniformLocation(shaderProgramMakeup, "uSampler"),
            opacity: gl.getUniformLocation(shaderProgramMakeup, "uOpacity"),
        },
    };

    // Here's where we call the routine that builds all the
    // objects we'll be drawing.
    const buffers = null;

    const texture = null;


    return {
        programInfo: programInfoMakeup,
        shaderProgram: shaderProgramMakeup,
        buffers: buffers,
        texture: texture,
    };
}

//
// initBuffers
//
// Initialize the buffers we'll need. For this demo, we just
// have one object -- a simple three-dimensional cube.
//
function initMaskBuffers(gl) {
    // Create a buffer for the square's positions.

    const positionBuffer = gl.createBuffer();
    //const colorBuffer = gl.createBuffer();
    const indexBuffer = gl.createBuffer();
    // Select the positionBuffer as the one to apply buffer
    // operations to from here out.

    // Now create an array of positions for the square.


    var eyeshadowVertices = [
        -1.0, 1.0, 0.0,
        -1.0, -1.0, 0.0,
        1.0, -1.0, 0.0,
        1.0, 1.0, 0.0
    ];
    var texelsTwo = [];
    // for (let i = 0; i < landmarks.length; i++) {
    //     eyeshadowVertices.push(landmarks[i].x)
    //     eyeshadowVertices.push(landmarks[i].y)
    //     eyeshadowVertices.push(landmarks[i].z)

    // }


    var drawOrder = [
        0, 1, 2,
        0, 2, 3
    ];


    texelsTwo = [
        0.0, 1.0,
        0.0, 0.0,
        1.0, 0.0,
        1.0, 1.0
    ];

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(
        gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array(drawOrder),
        gl.STATIC_DRAW
    );



    // Now pass the list of positions into WebGL to build the
    // shape. We do this by creating a Float32Array from the
    // JavaScript array, then use it to fill the current buffer.

    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(eyeshadowVertices),
        gl.DYNAMIC_DRAW
    );
    // Convert the array of colors into a table for all the vertices.



    const textureBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, textureBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(texelsTwo), gl.STATIC_DRAW);

    return {
        position: positionBuffer,
        // color: colorBuffer,
        indices: indexBuffer,
        texture: textureBuffer,
        vertexCount: drawOrder.length,
        count: eyeshadowVertices.length / 3
    };
}

//
// Initialize a texture and load an image.
// When the image finished loading copy it into the texture.
//
function loadMaskImage(gl, i) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Because images have to be download over the internet
    // they might take a moment until they are ready.
    // Until then put a single pixel in the texture so we can
    // use it immediately. When the image has finished downloading
    // we'll update the texture with the contents of the image.
    const level = 0;
    const internalFormat = gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    const pixel = new Uint8Array([0, 0, 0, 0]); // opaque blue
    gl.texImage2D(
        gl.TEXTURE_2D,
        level,
        internalFormat,
        width,
        height,
        border,
        srcFormat,
        srcType,
        pixel
    );

    if (i) {
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, i);

        // WebGL1 has different requirements for power of 2 images
        // vs non power of 2 images so check if the image is a
        // power of 2 in both dimensions.
        if (isPowerOf2(i.width) && isPowerOf2(i.height)) {
            // Yes, it's a power of 2. Generate mips.
            gl.generateMipmap(gl.TEXTURE_2D);
        } else {
            // No, it's not a power of 2. Turn of mips and set
            // wrapping to clamp to edge
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        }
    }

    return texture;
}

function isPowerOf2(value) {
    return (value & (value - 1)) === 0;
}

//
// Draw the scene.
//
function drawMaskScene(gl, programInfoMakeup, buffersMakeup, texture, opacity = 0.5, appliedColorValue = [0.0, 0.0, 0.0]) {

    gl.disable(gl.DEPTH_TEST);
    gl.enable(gl.BLEND);
    // gl.blendFunc(gl.SRC_ALPHA, gl.DST_ALPHA);
    gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA);


    const projectionMatrix = mat4.create();

    // note: js always has the first argument
    // as the destination to receive the result.
    // mat4.perspective(projectionMatrix,
    //     fieldOfView,
    //     aspect,
    //     zNear,
    //     zFar);

    // Set the drawing position to the "identity" point, which is
    // the center of the scene.
    const modelViewMatrix = mat4.create();

    // mat4.scale(modelViewMatrix, modelViewMatrix, [2.0, 2.0, 2.0]);
    // mat4.rotate(
    //     modelViewMatrix,
    //     modelViewMatrix,
    //     (180 * Math.PI) / 180,
    //     [0, 0, 1]
    // );
    // mat4.translate(modelViewMatrix, modelViewMatrix, [-0.5, -0.5, 0]);

    {
        const numComponents = 3; // pull out 2 values per iteration
        const type = gl.FLOAT; // the data in the buffer is 32bit floats
        const normalize = false; // don't normalize
        const stride = 0; // how many bytes to get from one set of values to the next
        // 0 = use type and numComponents above
        const offset = 0; // how many bytes inside the buffer to start from
        /**
         * BINDING VERTEX ARRAY
         */
        gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.position);
        gl.vertexAttribPointer(
            programInfoMakeup.attribLocations.vertexPosition,
            numComponents,
            type,
            normalize,
            stride,
            offset
        );
        gl.enableVertexAttribArray(
            programInfoMakeup.attribLocations.vertexPosition
        );

        /***
         * BINDING TEXTURE COORDINATES
         */
        gl.bindBuffer(gl.ARRAY_BUFFER, buffersMakeup.texture);
        gl.vertexAttribPointer(
            programInfoMakeup.attribLocations.texCoords,
            2,
            type,
            normalize,
            stride,
            offset
        );
        gl.enableVertexAttribArray(programInfoMakeup.attribLocations.texCoords);

    }

    // Tell WebGL to use our program when drawing
    //gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffersMakeup.indices);
    gl.useProgram(programInfoMakeup.program);

    // Set the shader uniforms

    gl.uniformMatrix4fv(
        programInfoMakeup.uniformLocations.projectionMatrix,
        false,
        projectionMatrix
    );
    gl.uniformMatrix4fv(
        programInfoMakeup.uniformLocations.modelViewMatrix,
        false,
        modelViewMatrix
    );
    gl.uniform1f(
        programInfoMakeup.uniformLocations.opacity,
        opacity,
    );
    gl.uniform3fv(
        programInfoMakeup.uniformLocations.appliedColorPositon,
        new Float32Array(appliedColorValue),
    );

    // gl.uniform4fv(
    //     programInfo.uniformLocations.color,
    //     1,
    //     new Float32Array([0.0, 1.0, 0.0, 1.0]),
    //     0
    // );

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.uniform1i(programInfoMakeup.uniformLocations.sampler, 0);
    {
        const vertexCount = buffersMakeup.vertexCount;
        const type = gl.UNSIGNED_SHORT;
        const offset = 0;
        gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
        // gl.drawArrays(gl.TRIANGLES, 0, buffersMakeup.count);//(Mode,firstElementor starting element,count or number of elements to be rendered)
    }
}

//
// Initialize a shader program, so WebGL knows how to draw our data
//
function initShaderProgram(gl, vsSource, fsSource) {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

    // Create the shader program

    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // If creating the shader program failed, alert

    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        alert(
            "Unable to initialize the shader program: " +
            gl.getProgramInfoLog(shaderProgram)
        );
        return null;
    }

    return shaderProgram;
}

//
// creates a shader of the given type, uploads the source and
// compiles it.
//
function loadShader(gl, type, source) {
    const shader = gl.createShader(type);

    // Send the source to the shader object

    gl.shaderSource(shader, source);

    // Compile the shader program

    gl.compileShader(shader);

    // See if it compiled successfully

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        alert(
            "An error occurred compiling the shaders: " + gl.getShaderInfoLog(shader)
        );
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

export {
    getSkinIssueProgramData,
    drawMaskScene,
    loadMaskImage,
    initMaskBuffers,
};

