import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import mainContext from "../../../Context/indexContext";

export default function PageNotFound() {
  // FEL 176 Added Page Not Found page
  const navigation = useNavigate();
  const themeContext = useContext(mainContext);
  return (
    <>
      <div
        class="d-flex align-items-center justify-content-center vh-100"
        style={{
          color: themeContext.getModeState.color,
        }}
      >
        <div class="text-center">
          <h1 class="display-1 fw-bold">404</h1>
          <p class="fs-3">
            {" "}
            <span class="text-danger">Oops!</span> Page not found.
          </p>
          <p class="lead">The page you’re looking for doesn’t exist.</p>
          <a onClick={() => navigation("/Blogs")} class="btn btn-primary">
            Explore Blogs
          </a>
        </div>
      </div>
    </>
  );
}
