import React from "react";
import BlogsLayout from "../Layouts/BlogsLayout";
import Featured from "../../Components/BlogsComponents/Featured";

export default function BlogsDashboard() {
  return <>
      <div>
        <BlogsLayout inner={Featured} />
      </div>
    </>
}
