import React from "react";
import BlogsLayout from "../Layouts/BlogsLayout";
import Blogs from "../../Components/BlogsComponents/Blogs";

export default function BlogsList() {
  return (
    <>
      <div>
        <BlogsLayout inner={Blogs} />
      </div>
    </>
  );
}
