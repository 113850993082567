import React, { useEffect, useState } from "react";
import likeButton from "../../../Images/likebutton.svg";
import unfillLikeButton from "../../../Images/unfillheart.svg";
import "./style.css";
import { Carousel, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BlogsApi } from "../../../Network/API";
import {
  timestrampToDate,
  esstimatedTimeToRead,
} from "../../../Pages/Utils/Utils";
import { isMobile } from "react-device-detect";

export default function SimilarBlogs(props) {
  // Create an instance of BlogsApi
  const blogsApis = new BlogsApi();

  // Use the useNavigate hook from React Router to enable navigation
  const navigate = useNavigate();

  // Function to navigate to another page with a given slug
  const navigateAnoterPage = (slug) => {
    navigate(`/Post-content/${slug}`);
    window.scroll(0, 0); // Scroll to the top of the page after navigation
  };

  // Function to divide an array into groups of 3
  const divideIntoGroups = (array) => {
    const result = [];
    for (let i = 0; i < array?.length; i += 3) {
      result.push(array.slice(i, i + 3)); // Push slices of 3 items into the result array
    }
    return result;
  };

  // Function to handle liking blogs
  const likedBlogs = (id) => {
    // Call the API to add a like for the specified blog id
    blogsApis
      .addGeneralLikesAndComment("/api/v1/blog-likes/", {
        blog_id: id,
      })
      .then((response) => {
        props.getApiData(); // Call the parent component's function to update data after liking
      });
  };

  // Divide the blogs into groups of 3 using the divideIntoGroups function
  const divideBlogs = divideIntoGroups(props.blogs);

  return (
    <>
      <Carousel className={`${isMobile ? "mobCarosel" : ""}`}>
        {divideBlogs?.length > 0 &&
          divideBlogs?.map((group, index) => {
            return (
              <Carousel.Item key={index}>
                <div className="container" key={index} style={{ backgroundColor: props.theme.getModeState.backgroundColor, color: props.theme.getModeState.color}} >
                  <div className="row">
                    {group.map((blog, blogIndex) => (
                      <div className="col-md-4 mb-3" role="button" key={blogIndex}>
                        <div className="card shadow" style={{ backgroundColor: props.theme.getModeState.tileBG, color: props.theme.getModeState.color, }} >
                          <img src={blog.banner_images} className="card-img-top card-images" alt="..." onClick={() => navigateAnoterPage(blog.slug)} />
                          <span className="badge bg-success text-capitalize category-badge">
                            {blog.blog_category?.name}
                          </span>
                          <a className="like-button">
                            {blog.user_liked && (
                              <img src={likeButton} className="p-1" onClick={() => likedBlogs(blog?.blog_id)} />
                            )}
                            {!blog.user_liked && (
                              <img src={unfillLikeButton} className="p-1" onClick={() => likedBlogs(blog?.blog_id)} />
                            )}
                          </a>
                          <div className="card-body" onClick={() => navigateAnoterPage(blog.slug)} >
                            <h5 className="card-title text-capitalize blog-titles"> {blog.title}{" "} </h5>
                            <p className="card-text blog-p" style={{ color: props.theme.getModeState.color }} >
                              {timestrampToDate(blog.posted_at)} {"  "} &bull;
                              {"  "} {esstimatedTimeToRead(blog.content)} min to
                              read
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
      </Carousel>
    </>
  );
}
