import React, { useEffect, useRef, useState } from 'react'
import { Col } from 'react-bootstrap';
import * as GaugeChart from 'gauge-chart';
import { AnalyzerApi } from '../../../Network/API';
import CustomToast from '../../AnalyzerComponents/CustomToast';
import "../SkinAge/style.css"


export const SkinAge = (props) => {
    const [getAgeDataState, setAgeDataState] = useState(0);
    const [getErrorFlagState, setErrorFlagState] = useState([false, '']);
    const gaugeRef = useRef();
    const gaugeFalseRef = useRef();


    //Load Results From API
    async function loadData() {
        let gaugeOptions = {
            hasNeedle: true,
            needleColor: '#7f007f',
            needleUpdateSpeed: 2590,
            arcColors: ['#7f007f', 'lightgray'],
            arcDelimiters: [1],
        }
        GaugeChart.gaugeChart(gaugeFalseRef.current, 150, gaugeOptions).updateNeedle(0);


        const ageData = new AnalyzerApi();
        ageData.getIssueData(props.base64, "Age").then((_response) => {
            if (_response.status === 200) {
                setAgeDataState(_response.data.analyzed_data['value']);
                let gaugeOptions = {
                    hasNeedle: true,
                    needleColor: '#7f007f',
                    needleUpdateSpeed: 2590,
                    arcColors: ['#7f007f', 'lightgray'],
                    arcDelimiters: [_response.data.analyzed_data['value']],
                }

                // Drawing and updating the chart
                GaugeChart.gaugeChart(gaugeRef.current,150,gaugeOptions).updateNeedle(_response.data.analyzed_data['value'])
            }
            else if (_response.status === 400) {
                setErrorFlagState([true, _response.data.message]);
            }
        });
    }

    // Drawing and updating the chart
    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className="">
                <div className='d-flex SkinAgeCards mt-2' style={{ background: props.tileBackground, color: props.tileFontColor }}>
                    {getErrorFlagState[0] ?
                        <>
                            <span className=' d-flex align-items-center text-center erroredAge'>{getErrorFlagState[1]}</span>
                            <CustomToast message={getErrorFlagState[1]} getErrorFlagState={getErrorFlagState[0]} />
                        </>
                        :
                        <>
                            <Col md={4} xs={4} className="d-flex justify-content-center align-item-center">
                                <div ref={gaugeRef} className='p-1' style={{ display: getAgeDataState > 0 ? '' : 'none' }}></div>
                                <div ref={gaugeFalseRef} className='p-1' style={{ display: getAgeDataState > 0 ? 'none' : '' }}></div>
                            </Col>
                            <Col md={8} xs={8} className='d-flex'>
                                <Col md={12} xs={12} className='d-flex'>
                                    <div className="flex-row">
                                        <div className="mt-2 flex-row">
                                            <p className='myH3 mb-0'>
                                                Skin Age: {getAgeDataState}
                                            </p>
                                            <p className='mb-1'></p>
                                        </div>
                                        <div className="myH3 flex-row"></div>
                                        <div className="mt-2 myH4 d-flex align-items-end flex-row">
                                            <p>
                                                Skin age is the condition and appearance of one's skin compared to their chronological age, influenced by sun exposure, lifestyle habits, genetics, and aging.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Col>
                        </>
                    }

                </div>
            </div>
        </>
    )
}